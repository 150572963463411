import { mutationTree, getterTree, actionTree } from 'typed-vuex'
import {
  ReportCategory,
  StorySourceType,
  StoryTransactionType,
  Story,
} from '~/models/schema'

export const state = () => ({})

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {})

export const actions = actionTree({ state, getters, mutations }, {})
