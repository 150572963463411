import { mutationTree, getterTree, actionTree } from 'typed-vuex'
import { Condo, Report } from '~/models/schema'

export const state = () => ({
  report: null as Report | null,
  // reports: [] as Report[],
})

export const getters = getterTree(state, {
  report: (state) => state.report,
})

export const mutations = mutationTree(state, {
  setReport(state, report: Report): void {
    state.report = report
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // TODO APIからデータを取得してstoreにセットするaction
    // async fetchReports({ commit }) : Promise<void> {
    //   // console.log("fetchList")
    // },
  }
)
