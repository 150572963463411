export default ({ $gtag, store }) => {
  const user = store.state.users.user
  if (user) {
    // GA4 set user id
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_id
    $gtag('set', 'user_id', user.id)
    // GA4 set custom definition
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_properties
    $gtag('set', 'user_properties', {
      company_id: user.company.id,
      company_name: user.company.name,
      department_name: user.company.department_name,
      plan_name: user.company.payment.plan.name,
    })
    // Memo
    // 一番最初の通信では上記の情報は送信されず、二回目以降の通信で利用されています。
    // 同一セッションとしてGA4上で集計されるはずなので問題ないはずですが
    // 本番環境ではちゃんと意図したとおりになっているか検証の必要性あり。
  }
}
