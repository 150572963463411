//
//
//
//

export default {
  data() {
    return {}
  },

  head: {
    title: 'ツナガルオンライン査定',
    titleTemplate: 'ツナガルオンライン査定 | %s',
    meta: [
      {
        httpEquiv: 'Content-Type',
        content: 'text/html; charset=UTF-8',
        charset: 'utf-8',
      },
    ],
    link: [
      {
        rel: 'stylesheet',
        type: 'text/css',
        id: 'u0',
        href: 'https://rakko.tools/tools/129/lib/tinymce/skins/ui/oxide/content.min.css',
      },
      {
        rel: 'stylesheet',
        type: 'text/css',
        id: 'u1',
        href: 'https://rakko.tools/tools/129/lib/tinymce/skins/content/default/content.min.css',
      },
    ],
  },

  computed: {
    currentPath() {
      return this.$route.path
    },
  },

  mounted() {},
}
