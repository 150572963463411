import Vue from 'vue'

Vue.prototype.$commonFormatter = () => {
  const toFloorDecimal = (value, n) =>
    Math.floor(value * Math.pow(10, n)) / Math.pow(10, n)

  const toRoundDecimal = (value, n) =>
    Math.round(value * Math.pow(10, n)) / Math.pow(10, n)

  return {
    toFloorDecimal,
    toRoundDecimal,
  }
}
