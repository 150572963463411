import { mutationTree, getterTree, actionTree } from 'typed-vuex'

export const state = () => ({
  title: '',
})

export const getters = getterTree(state, {
  title: (state) => state.title,
})

export const mutations = mutationTree(state, {
  setTitle(state, title: string): void {
    state.title = title
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // setTitle({ commit }) : void {
    //   commit('setTitle', )
    // },
  }
)
