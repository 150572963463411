import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { User } from '~/models/schema'

type State = {
  user?: User
}

export const state = (): State => ({
  user: undefined,
})

export const mutations = mutationTree(state, {
  setUser(state: State, user: User): void {
    state.user = user
  },
})

// https://typed-vuex.roe.dev/store/getters
export const getters = getterTree(state, {})

export const actions = actionTree(
  { state, mutations },
  {
    async fetchUser({ commit }) {
      const user = await this.$api.users.usersMeDetail()
      commit('setUser', user.data)
    },
  }
)
