






import Vue from 'vue'

export default Vue.extend({
  props: {},

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {},
})
