/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ReportCategory {
  House = 'house',
  Land = 'land',
  Condo = 'condo',
  Area = 'area',
}

export enum LandShape {
  ShapedLand = 'shaped_land',
  UnshapedLand = 'unshaped_land',
  Manual = 'manual',
  Unknown = 'unknown',
  AlleyShapeLand = 'alley_shape_land',
}

export enum Ownership {
  Freehold = 'freehold',
  MostlyFreehold = 'mostly_freehold',
  Leasehold = 'leasehold',
  LimitedLeasehold = 'limited_leasehold',
  OldLeasehold = 'old_leasehold',
  OldSuperficies = 'old_superficies',
  NormalLeasehold = 'normal_leasehold',
  NormalSuperficies = 'normal_superficies',
  LimitedNormalLeasehold = 'limited_normal_leasehold',
  LimitedSuperficies = 'limited_superficies',
  Unknown = 'unknown',
}

export enum BuildingConstructionType {
  Wood = 'wood',
  Lgs = 'lgs',
  Wgs = 'wgs',
  Rc = 'rc',
  Src = 'src',
  Cb = 'cb',
  Other = 'other',
  Unknown = 'unknown',
}

export enum CondoConstructionType {
  Wood = 'wood',
  Rc = 'rc',
  Src = 'src',
  Lgs = 'lgs',
  Wgs = 'wgs',
  Cb = 'cb',
  Other = 'other',
  Unknown = 'unknown',
}

export enum LandCorner {
  Ippou = 'ippou',
  Kado = 'kado',
  Nihou = 'nihou',
  Sanhou = 'sanhou',
  Yonhou = 'yonhou',
  Nashi = 'nashi',
  Unknown = 'unknown',
}

export enum AreaClassification {
  UrbanizationArea = 'urbanization_area',
  UrbanizationControlArea = 'urbanization_control_area',
  SuburbsArea = 'suburbs_area',
  SemiCityPlanningArea = 'semi_city_planning_area',
  OutsideArea = 'outside_area',
  Unknown = 'unknown',
}

export enum FirePreventionLaw {
  FirePreventiveDistrict = 'fire_preventive_district',
  SemiFirePreventiveDistrict = 'semi_fire_preventive_district',
  Hou22JoDistrict = 'hou22jo_district',
  Unspecified = 'unspecified',
  Unknown = 'unknown',
}

export enum Direction {
  South = 'south',
  East = 'east',
  West = 'west',
  North = 'north',
  Southeast = 'southeast',
  Southwest = 'southwest',
  Northeast = 'northeast',
  Northwest = 'northwest',
  Unknown = 'unknown',
}

export enum RoadType {
  PublicRoad = 'public_road',
  PrivateRoad = 'private_road',
  Unknown = 'unknown',
}

export enum CurrentState {
  Living = 'living',
  Vacant = 'vacant',
  Rent = 'rent',
  UnderConstruction = 'under_construction',
  Unknown = 'unknown',
}

export enum CondoManagementForm {
  AllConsignment = 'all_consignment',
  PartialConsignment = 'partial_consignment',
  SelfManagement = 'self_management',
  Unknown = 'unknown',
}

export enum CondoManagementState {
  Nikkin = 'nikkin',
  Jochu = 'jochu',
  Junkai = 'junkai',
  Sumikomi = 'sumikomi',
  Unknown = 'unknown',
}

/**
 * 取引の種別
 */
export enum StoryTransactionType {
  Unknown = 'unknown',
  Traded = 'traded',
  Contracted = 'contracted',
}

/**
 * 事例の発生源
 */
export enum StorySourceType {
  Unknown = 'unknown',
  Db = 'db',
  Posted = 'posted',
}

/**
 * 事例の用途
 */
export enum StoryUse {
  Unknown = 'unknown',
  CostApproachAssessment = 'cost_approach_assessment',
  MarketApproachAssessment = 'market_approach_assessment',
  SalePriceApproachAssessment = 'sale_price_approach_assessment',
  CompetitorAnalysis = 'competitor_analysis',
}

/**
 * 査定方法の種別
 */
export enum AssessmentCategory {
  Cost = 'cost',
  Market = 'market',
  SalePrice = 'sale_price',
}

export enum RecordType {
  PostedLandPrice = 'posted_land_price',
  PrefecturalLandPrice = 'prefectural_land_price',
}

export enum SpotCategory {
  ShoppingFacilities = 'shopping_facilities',
  PublicFacilities = 'public_facilities',
  MedicalFacilities = 'medical_facilities',
  EducationalFacilities = 'educational_facilities',
  WelfareFacilities = 'welfare_facilities',
  Park = 'park',
  TouristFacilities = 'tourist_facilities',
  RecreationalFacilities = 'recreational_facilities',
  AbhorrentFacilities = 'abhorrent_facilities',
  Shelter = 'shelter',
}

export enum PrintSettingColor {
  Blue = 'blue',
  Darkblue = 'darkblue',
  Navy = 'navy',
  Red = 'red',
  Green = 'green',
  Orange = 'orange',
  Custom = 'custom',
}

export enum ExcludedPagesKey {
  Cover = 'cover',
  Greeting = 'greeting',
  Summary = 'summary',
  Feature = 'feature',
  Assessment = 'assessment',
  OfferPrice = 'offer_price',
  MarketAnalysis = 'market_analysis',
  Spot = 'spot',
  AreaDisasterPreventionInformation = 'area_disaster_prevention_information',
  StatisticsInformation = 'statistics_information',
  Fundamentals = 'fundamentals',
}

export enum ExcludedPageKey {
  CoverPage = 'cover_page',
  GreetingPage = 'greeting_page',
  SummaryPage = 'summary_page',
  FeaturePage = 'feature_page',
  StoriesDistributionPage = 'stories_distribution_page',
  ArrangementPage1 = 'arrangement_page1',
  ArrangementPage2 = 'arrangement_page2',
  ConclusionPage = 'conclusion_page',
  HouseCostAndMarketAssessmentPagesAssessmentMethodsPage = 'house_cost_and_market_assessment_pages_assessment_methods_page',
  HouseCostAndMarketAssessmentPagesCostSectionCoverPage = 'house_cost_and_market_assessment_pages_cost_section_cover_page',
  HouseCostAndMarketAssessmentPagesCostApproachAssessmentStoriesPage = 'house_cost_and_market_assessment_pages_cost_approach_assessment_stories_page',
  HouseCostAndMarketAssessmentPagesCostApproachAssessmentScoresPage = 'house_cost_and_market_assessment_pages_cost_approach_assessment_scores_page',
  HouseCostAndMarketAssessmentPagesLandPricePage = 'house_cost_and_market_assessment_pages_land_price_page',
  HouseCostAndMarketAssessmentPagesBuildingPricePage = 'house_cost_and_market_assessment_pages_building_price_page',
  HouseCostAndMarketAssessmentPagesCostApproachAssessmentSummaryPage = 'house_cost_and_market_assessment_pages_cost_approach_assessment_summary_page',
  HouseCostAndMarketAssessmentPagesMarketSectionCoverPage = 'house_cost_and_market_assessment_pages_market_section_cover_page',
  HouseCostAndMarketAssessmentPagesMarketApproachAssessmentStoriesPage = 'house_cost_and_market_assessment_pages_market_approach_assessment_stories_page',
  HouseCostAndMarketAssessmentPagesMarketApproachAssessmentScoresPage = 'house_cost_and_market_assessment_pages_market_approach_assessment_scores_page',
  HouseCostAndMarketAssessmentPagesHouseSummaryPage = 'house_cost_and_market_assessment_pages_house_summary_page',
  HouseCostAndMarketAssessmentPagesAssessmentSummaryPage = 'house_cost_and_market_assessment_pages_assessment_summary_page',
  HouseCostAssessmentPagesCostApproachAssessmentCoverPage = 'house_cost_assessment_pages_cost_approach_assessment_cover_page',
  HouseCostAssessmentPagesCostApproachAssessmentStoriesPage = 'house_cost_assessment_pages_cost_approach_assessment_stories_page',
  HouseCostAssessmentPagesCostApproachAssessmentScoresPage = 'house_cost_assessment_pages_cost_approach_assessment_scores_page',
  HouseCostAssessmentPagesLandPricePage = 'house_cost_assessment_pages_land_price_page',
  HouseCostAssessmentPagesBuildingPricePage = 'house_cost_assessment_pages_building_price_page',
  HouseCostAssessmentPagesCostApproachAssessmentSummaryPage = 'house_cost_assessment_pages_cost_approach_assessment_summary_page',
  HouseMarketAssessmentPagesMarketApproachAssessmentCoverPage = 'house_market_assessment_pages_market_approach_assessment_cover_page',
  HouseMarketAssessmentPagesMarketApproachAssessmentStoriesPage = 'house_market_assessment_pages_market_approach_assessment_stories_page',
  HouseMarketAssessmentPagesScoresPage = 'house_market_assessment_pages_scores_page',
  HouseMarketAssessmentPagesHouseSummaryPage = 'house_market_assessment_pages_house_summary_page',
  LandAssessmentPagesMarketApproachAssessmentCoverPage = 'land_assessment_pages_market_approach_assessment_coverPage',
  LandAssessmentPagesMarketApproachAssessmentStoriesPage = 'land_assessment_pages_market_approach_assessment_storiesPage',
  LandAssessmentPagesScoresPage = 'land_assessment_pages_scores_page',
  LandAssessmentPagesLandSummaryPage = 'land_assessment_pages_land_summary_page',
  CondoAssessmentPagesMarketApproachAssessmentCoverPage = 'condo_assessment_pages_market_approach_assessment_cover_page',
  CondoAssessmentPagesMarketApproachAssessmentStoriesPage = 'condo_assessment_pages_market_approach_assessment_stories_page',
  CondoAssessmentPagesScoresPage = 'condo_assessment_pages_scores_page',
  CondoAssessmentPagesCondoSummaryPage = 'condo_assessment_pages_condo_summary_page',
  OfferPriceCoverPage = 'offer_price_cover_page',
  OfferPriceStoriesPage = 'offer_price_stories_page',
  OfferPriceScoresPage = 'offer_price_scores_page',
  OfferPriceSummaryPage = 'offer_price_summary_page',
  HouseMarketAnalysisPagesAnalysisCoverPage = 'house_market_analysis_pages_analysis_cover_page',
  HouseMarketAnalysisPagesMarketAnalysisStoriesPage = 'house_market_analysis_pages_market_analysis_stories_page',
  HouseMarketAnalysisPagesMarketAnalysisLandPriceVolatilityPage = 'house_market_analysis_pages_market_analysis_land_price_volatility_page',
  HouseMarketAnalysisPagesLandSellStoriesStatisticPage = 'house_market_analysis_pages_land_sell_stories_statistic_page',
  HouseMarketAnalysisPagesHouseSellStoriesStatisticPage = 'house_market_analysis_pages_house_sell_stories_statistic_page',
  LandMarketAnalysisPagesMarketAnalysisCoverPage = 'land_market_analysis_pages_market_analysis_cover_page',
  LandMarketAnalysisPagesMarketAnalysisStoriesPage = 'land_market_analysis_pages_market_analysis_stories_page',
  LandMarketAnalysisPagesMarketAnalysisLandPriceVolatilityPage = 'land_market_analysis_pages_market_analysis_land_price_volatility_page',
  LandMarketAnalysisPagesLandSellStoriesStatisticPage = 'land_market_analysis_pages_land_sell_stories_statistic_page',
  CondoMarketAnalysisPagesMarketAnalysisCoverPage = 'condo_market_analysis_pages_market_analysis_cover_page',
  CondoMarketAnalysisPagesCondoSellStoriesMapPage = 'condo_market_analysis_pages_condo_sell_stories_map_page',
  CondoMarketAnalysisPagesRentSellStoriesMapPage = 'condo_market_analysis_pages_rent_sell_stories_map_page',
  CondoMarketAnalysisPagesCondoSellStoriesStatisticPage = 'condo_market_analysis_pages_condo_sell_stories_statistic_page',
  CondoMarketAnalysisPagesCondoSellStoriesLogPage = 'condo_market_analysis_pages_condo_sell_stories_log_page',
  CondoMarketAnalysisPagesSalesPeriodPage = 'condo_market_analysis_pages_sales_period_page',
  SpotCoverPage = 'spot_cover_page',
  TransportNodePage = 'transport_node_page',
  RouteMapPage1 = 'route_map_page1',
  RouteMapPage2 = 'route_map_page2',
  PublicFacilitiesPage = 'public_facilities_page',
  ShoppingFacilitiesPage = 'shopping_facilities_page',
  MedicalFacilitiesPage = 'medical_facilities_page',
  EducationalFacilitiesPage = 'educational_facilities_page',
  WelfareFacilitiesPage = 'welfare_facilities_page',
  ParksPage = 'parks_page',
  TouristFacilitiesPage = 'tourist_facilities_page',
  RecreationalFacilitiesPage = 'recreational_facilities_page',
  AbhorrentFacilitiesPage = 'abhorrent_facilities_page',
  SheltersPage = 'shelters_page',
  AreaDisasterPreventionInformationCoverPage = 'area_disaster_prevention_information_cover_page',
  UseDistrictPage = 'use_district_page',
  ElevationAndSlopeAngleFifthMeshPage = 'elevation_and_slope_angle_fifth_mesh_page',
  SeismicHazardFaultPage = 'seismic_hazard_fault_page',
  AmplificationFactorPage = 'amplification_factor_page',
  ProbabilityOfExceedancePage = 'probability_of_exceedance_page',
  CrowdCityPage = 'crowd_city_page',
  ProbabilityOfLiquefactionPage = 'probability_of_liquefaction_page',
  WaterDepthAreaPage1 = 'water_depth_area_page1',
  WaterDepthAreaPage2 = 'water_depth_area_page2',
  WaterDepthAreaPage3 = 'water_depth_area_page3',
  LowPriceAreaPage = 'low_price_area_page',
  SedimentDisasterHazardAreaPage = 'sediment_disaster_hazard_area_page',
  StatisticsInformationCoverPage = 'statistics_information_cover_page',
  IncomePerPersonMapPage = 'income_per_person_map_page',
  FundamentalsCoverPage = 'fundamentals_cover_page',
  FlowOfSalePage = 'flow_of_sale_page',
  ContractTypePage = 'contract_type_page',
  ExpensesPage = 'expenses_page',
  AboutCompanyPage = 'about_company_page',
  Recommendation1Page = 'recommendation1_page',
  Recommendation2Page = 'recommendation2_page',
}

export enum CustomName {
  NomuCom = 'nomu.com',
  Nsl = 'nsl',
  None = 'none',
}

export enum TransportNodeType {
  Station = 'station',
  Busstop = 'busstop',
}

export enum WaterDepthLevel {
  Level1 = 'level_1',
  Level2 = 'level_2',
  Level3 = 'level_3',
  Level4 = 'level_4',
  Level5 = 'level_5',
  Level6 = 'level_6',
}

/**
* 液状化の可能性
https://docs.google.com/spreadsheets/d/1wpmD0jHFm4yN49g8degmkQyqdPX_wuk_o676O216p4g/edit#gid=1516355427
*/
export enum JapanSeismicHazardMeshProbabilityOfLiquefactionType {
  Low = 'low',
  LowSlightly = 'low_slightly',
  Middle = 'middle',
  HighSlightly = 'high_slightly',
  High = 'high',
  Unknown = 'unknown',
}

export enum UseDistrictCode {
  Itei = 'itei',
  Nitei = 'nitei',
  Ichu = 'ichu',
  Nichu = 'nichu',
  Ijyu = 'ijyu',
  Nijyu = 'nijyu',
  Denjyu = 'denjyu',
  Jyunjyu = 'jyunjyu',
  Kinsho = 'kinsho',
  Shogyo = 'shogyo',
  Jyunko = 'jyunko',
  Kogyo = 'kogyo',
  Kosen = 'kosen',
  Unspecified = 'unspecified',
  Unknown = 'unknown',
}

/**
* 決済ステータス
active: 課金状態
inactive: 非課金状態(紐づくuserはログイン時に認証ができなくなります。)

auth0のDBとの連動はしていません。
手動運用時(~202301)まではauth0をブロックする運用を行っていました。
しかし202302からはアプリケーションのDB上の属性だけで認証を制御するようにしています。
*/
export enum PaymentStatus {
  Active = 'active',
  Inactive = 'inactive',
}

/**
 * 帳票に添付する画像の種類
 */
export enum PhotoCategory {
  Unknown = 'unknown',
  Appearance = 'appearance',
  Other = 'other',
}

/**
* 取り込み用事例データファイルのステータス
unknown: 不明
wait: 取り込み処理が未実行
active: 取り込み処理を実行中
completed: 取り込み処理が完了
failed: 取り込み処理が失敗
*/
export enum ImportFileStatus {
  Unknown = 'unknown',
  Wait = 'wait',
  Active = 'active',
  Completed = 'completed',
  Failed = 'failed',
}

export interface Company {
  id: number

  /** @example コラビット不動産 */
  name: string

  /** @example 東京都港区 芝浦1丁目13−10 第3東運ビル 8F */
  address: string

  /** @example 03-1234-5678 */
  tel: string

  /** @example 03-1234-5678 */
  fax: string

  /** @example 104-0052 */
  zip: string

  /** カスタム帳票の名称 */
  custom_name: CustomName

  /**
   * (非推奨)部門名 -> Officeモデルで管理することに変更(2023.01)
   * @example 田町本店
   */
  department_name?: string

  /**
   * 宅建業の免許番号
   * @example 東京都知事免許（１）第１０５３１９号
   */
  license_number?: string

  /** @example companies/{company_id}/logo.png */
  icon_image_key: string

  /** @example https://some.where/images/logo.png */
  icon_image_url: string

  /** @example https://some.where/images/greeting.png */
  greeting_image_url: string

  /**
   * 決済情報を管理するモデルです。
   * planに1x1でひも付き、companiesと1*nの関係でひも付きます。
   * ただしcompanyモデルから参照される実装を想定しているのでcompaniesの属性は持たない設計にしています。
   */
  payment: Payment

  /**
   * 帳票の有効期限を設定します。
   * これを超過すると自動的に削除されます。
   * 設定のない場合（null）は有効期限はありません。
   * @example 90
   */
  report_expiration_days?: number
}

export interface Office {
  id: number

  /**
   * 企業ID
   * @example 12345
   */
  company_id: number

  /** 担当者一覧 */
  members: {
    user_id: number
    email: string
    given_name: string
    family_name: string
    tel1: string
    role_name: any
    selected: boolean
  }[]

  /**
   * 本店フラグ(trueの場合に本店)
   * @example true
   */
  head_office: boolean

  /**
   * 支店・センター名
   * @example 芝浦本店
   */
  name: string

  /**
   * 郵便番号
   * @example 104-0052
   */
  zip: string

  /**
   * 住所
   * @example 東京都港区 芝浦1丁目13−10 第3東運ビル 8F
   */
  address: string

  /**
   * 電話番号
   * @example 53068444
   */
  tel1: string

  /**
   * フリーコール
   * @example 53068444
   */
  tel2: string

  /**
   * FAX番号
   * @example 53068444
   */
  fax: string

  /**
   * 営業時間
   * @example 10:00~18:00
   */
  business_hours_text: string

  /**
   * 定休日
   * @example 火・水
   */
  regular_day_off_text: string

  /**
   * アクセス
   * @example JR目黒駅より・・・
   */
  access_text: string

  /**
   * 駐車場
   * @example ...
   */
  parking_text: string

  /**
   * マップ画像 S3 key
   * @example companies/{company_id}/offices/{office_id}/map_image/1.png
   */
  map_image_key: string

  /**
   * マップ画像URL
   * @example https://some.where/images/logo.png
   */
  map_image_url: string

  /**
   * 店舗イメージ S3 key
   * @example companies/{company_id}/offices/{office_id}/office_image/1.png
   */
  office_image_key: string

  /**
   * 店舗イメージURL
   * @example https://some.where/images/logo.png
   */
  office_image_url: string
}

/**
* 独自資料
PDFや画像をアップロードして独自の資料ページを作成することができます。
*/
export interface Document {
  /**
   * 独自資料ID
   * @example 12345
   */
  id: number

  /**
   * ユーザーID
   * @example 12345
   */
  user_id?: number

  /**
   * 資料タイトル
   * @example 売却応援キャンペーン
   */
  title: string

  /**
   * ファイル S3 key
   * @example companies/{company_id}/users/{user_id}/documents/{office_id}.png
   */
  file_key: string

  /**
   * ファイルURL
   * アップロード出来るのは下記の拡張子のファイルです。
   * - png
   * - jpeg
   * - jpg
   * - pdf
   * @example https://some.where/files/abc.pdf
   */
  file_url: string

  /**
   * 作成時に自動でランダムな文字列（uuid）が設定されます。これは独自資料を査定書に挿入する際のページ識別子として使われます。
   * @example 291da1ce-a40f-ea97-e661-91d270bc3d6a
   */
  page_key: string

  /**
   * 独自資料の作成日です。
   * @example 2023-03-07 03:40:29.95429
   */
  created_at: string

  /**
   * 独自資料の最終更新日です。
   * @example 2023-03-07 03:40:29.95429
   */
  updated_at: string
}

export interface User {
  id: number

  /**
   * 認証されたユーザーの一意識別子
   * @example *******************
   */
  sub: string

  /**
   * 登録メールアドレス
   * @example test@example.com
   */
  email: string

  /**
   * 名前
   * @example 太郎
   */
  given_name?: string

  /**
   * 名字
   * @example 査定
   */
  family_name?: string

  /**
   * 名前のふりがな
   * @example たろう
   */
  given_name_ruby: string

  /**
   * 名字のふりがな
   * @example さてい
   */
  family_name_ruby: string

  /**
   * 電話番号1
   * @example 9012341234
   */
  tel1?: string

  /**
   * 電話番号2
   * @example 53068444
   */
  tel2?: string

  /** ご挨拶文 */
  greeting_text: string

  /**
   * 従業員番号
   * 企業IDと従業員番号の結合文字列がユニークキーです。
   * CSV取込機能の際はこのキーを元に上書き保存されます。
   * @example 12345
   */
  code?: string

  /**
   * プロフィール写真 S3 key
   * @example companies/{company_id}/offices/{office_id}/office_image/1.png
   */
  profile_image_key?: string

  /**
   * プロフィール写真URL
   * @example https://some.where/images/photo.png
   */
  profile_image_url?: string
  company: Company

  /** ユーザーの権限 */
  role_name: 'admin' | 'company_admin' | 'office_admin' | 'member'

  /** 権限設定を管理するデータです。 */
  permission_setting: PermissionSetting
}

/**
 * 権限設定を管理するデータです。
 */
export interface PermissionSetting {
  /**
   * 担当者カスタムフィールド管理機能の有効フラグ
   * @example false
   */
  enabled_user_custom_fields: boolean

  /**
   * 帳票写真管理機能の有効フラグ
   * @example false
   */
  enabled_report_photos: boolean

  /**
   * 帳票カスタムフィールド管理機能の有効フラグ
   * @example false
   */
  enabled_report_custom_fields: boolean

  /**
   * 店舗管理機能(店舗管理者用)の有効フラグ
   * @example false
   */
  enabled_offices_level1: boolean

  /**
   * 店舗管理機能(企業管理者用)の有効フラグ
   * 一部の属性はこの権限がないと編集ができない。
   * @example false
   */
  enabled_offices_level2: boolean

  /**
   * 分譲価格比の査定の有効フラグ
   * 現在はNSLのみがtrueを返却します
   * @example false
   */
  enabled_sale_price_approach_assessment: boolean
}

/**
* 企業担当者がユーザーに対して設定できるカスタムフィールドです。
(例: 出身地、資格など)
*/
export interface UserCustomField {
  id: number

  /** 企業ID */
  company_id?: number

  /** 項目名 */
  name: string

  /** 詳細情報 */
  description: string

  /**
   * 項目の値タイプ
   * - string: 文字列(単一行)
   * - text: 文字列(複数行)
   * - number: 数値
   */
  value_type: 'string' | 'text' | 'number'

  /**
   * 優先度
   * 小さい数値のほうが優先度が高い。
   */
  priority: number

  /**
   * 有効フラグ
   * 有効な場合はtrueを返却します。
   */
  enabled: boolean
}

/**
* 企業担当者がユーザーに対して設定できるカスタムフィールドです。
(例: 出身地、資格など)
*/
export interface UserCustomFieldValue {
  id: number
  user_id: number
  user_custom_field_id: number

  /**
   * 項目の値
   * DB上はstring型で値を保存します。
   * APIで返却する際にはvalue_typeに応じた型に変換されます。
   */
  value: string
}

/**
* 企業担当者が帳票に対して設定できるカスタムフィールドです。
(例: 購入見込客層、地域特性コメントなど)
*/
export interface ReportCustomField {
  id: number

  /** 企業ID */
  company_id?: number

  /** 項目名 */
  name: string

  /** 詳細情報 */
  description: string

  /**
   * 項目の値タイプ
   * - string: 文字列(単一行)
   * - text: 文字列(複数行)
   * - number: 数値
   */
  value_type: 'string' | 'text' | 'number'

  /**
   * 優先度
   * 小さい数値のほうが優先度が高い。
   */
  priority: number

  /**
   * 有効フラグ
   * 有効な場合はtrueを返却します。
   */
  enabled: boolean

  /**
   * 入力フォームの接頭辞です。
   * @example ¥
   */
  prefix_text: string

  /**
   * 入力フォームの接尾辞です。
   * @example 人
   */
  suffix_text: string
}

/**
* 企業担当者が帳票に対して設定した属性の値です。
(例: 購入見込客層、地域特性コメントなど)
*/
export interface ReportCustomFieldValue {
  id: number
  report_id: number
  report_custom_field_id: number

  /**
   * 項目の値
   * DB上はstring型で値を保存します。
   * APIで返却する際にはvalue_typeに応じた型に変換されます。
   */
  value: string
}

export interface Plan {
  id: number

  /**
   * プランの名称
   * エンドユーザ向けに表示される名称です。
   * @example エントリープラン
   */
  name: string

  /**
   * プランの名称(略称)
   * 管理画面などで表示される名称です。
   * @example エントリー
   */
  name_short: string

  /**
   * 無料査定数(これを超過する場合は別途料金が発生します)
   * @example 20
   */
  monthly_reports_count: number

  /**
   * 無料査定数が無制限の場合にtrue
   * @example false
   */
  reports_count_unlimited: boolean
}

/**
* 決済情報を管理するモデルです。
planに1x1でひも付き、companiesと1*nの関係でひも付きます。
ただしcompanyモデルから参照される実装を想定しているのでcompaniesの属性は持たない設計にしています。
*/
export interface Payment {
  id: number
  plan: Plan

  /**
   * 残りの無料査定数(月間無料査定階数 - 月間帳票数)
   * @example 10
   */
  remaining_monthly_reports_count: number

  /**
   * 課金の対象の場合にtrueを返却します
   * @example true
   */
  billing?: boolean

  /**
   * お試し無料期間の場合にtrueを返却します
   * @example false
   */
  trial_period: boolean

  /**
   * 今月累計査定数
   * 無料期間内の査定数はカウントされません。
   * @example 5
   */
  reports_count_this_month: number

  /** 利用開始日 */
  activated_at: string

  /**
   * 決済ステータス
   * active: 課金状態
   * inactive: 非課金状態(紐づくuserはログイン時に認証ができなくなります。)
   *
   * auth0のDBとの連動はしていません。
   * 手動運用時(~202301)まではauth0をブロックする運用を行っていました。
   * しかし202302からはアプリケーションのDB上の属性だけで認証を制御するようにしています。
   */
  status: PaymentStatus
}

/**
* 請求内容を管理するモデルです。
paymentに一対一でひも付きます
*/
export interface Invoice {
  /**
   * 請求ID
   * @example 123
   */
  id: number

  /**
   * 決済ID
   * @example 123
   */
  payment_id: number

  /**
   * 請求年
   * @example 2023
   */
  target_year: number

  /**
   * 請求月
   * @example 1
   */
  target_month: number

  /**
   * 会社名
   * @example 株式会社コラビット
   */
  company_name: string

  /**
   * 請求年月のプラン名
   * プラン変更した場合は下記のように設定されます。
   * - 当月適用(現状プランより上位のプラン) -> 当月適用されたプラン名
   * - 翌月適用(現状プランより下位のプラン) -> 変更前の当月のプラン名
   * @example エントリープラン
   */
  plan_name: string

  /**
   * 請求年月のプランの査定可能回数
   * @example 20
   */
  monthly_reports_count: number

  /**
   * 請求年月の査定書の作成回数(削除含む)
   * @example 25
   */
  reports_count: number

  /**
   * 請求年月の請求金額(税抜)
   * 計算式: 基本料金 + 超過分の料金
   * 例: 17800 = 12800 + 1000 * (25 - 20)
   *
   * 請求金額の確定時点(毎月1日0:00)に下記の場合でも無関係に算出しています。
   * - 停止状態(status=inactive)
   * - デモアカウント(billing=false)
   * @example 17800
   */
  price: number

  /**
   * 請求年月の請求内容に関するメモ
   * @example あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。
   */
  memo: string
}

export interface PostedLandPriceStory {
  /**
   * id
   * @example 1
   */
  id?: number
  record_type?: RecordType

  /**
   * 標準地コード_見出し番号
   * @example 000
   */
  represented_land_code_index_number?: string

  /**
   * 標準地コード_一連番号
   * @example 017
   */
  represented_land_code_sequence_number?: string

  /**
   * 前年度標準地コード_見出し番号
   * @example 000
   */
  previous_represented_land_code_index_number?: string

  /**
   * 前年度標準地コード_一連番号
   * @example 017
   */
  previous_represented_land_code_sequence_number?: string

  /**
   * 年度
   * @example 2021
   */
  year?: number

  /**
   * 公示価格 or 都道府県地価
   * @example 1560000
   */
  price?: number

  /**
   * （仕様書に説明なし）
   * @example 1
   */
  selected_land_status?: number

  /**
   * 標準地行政コード
   * @example 13103
   */
  administrative_area_code?: string

  /**
   * 標準地市区町村名称
   * @example 港
   */
  city_name?: string

  /**
   * 所在地
   * @example 東京都　港区芝浦２－３－２７
   */
  address?: string

  /**
   * 所在地
   * @example 東京都　千代田区六番町６番１外
   */
  location?: string

  /**
   * 地積
   * @example 993
   */
  acreage?: number

  /**
   * 利用現況
   * @example 住宅,その他
   */
  current_use?: string

  /**
   * 利用状況表示
   * @example 共同住宅
   */
  usage_description?: string

  /**
   * 建物構造
   * @example RC11
   */
  building_structure?: string

  /**
   * 形状
   * @example 台形
   */
  configuration?: string

  /**
   * 前面道路状況
   * @example 区道
   */
  frontal_road?: string

  /**
   * 前面道路の方位
   * @example 北西
   */
  direction_of_frontal_road?: string

  /**
   * 前面道路の幅員
   * @example 11
   */
  width_of_frontal_road?: number

  /**
   * 側道状況
   * @example 側道
   */
  side_road?: string

  /**
   * 側道の方位
   * @example 南
   */
  direction_of_side_road?: string

  /**
   * 駅名
   * @example 田町
   */
  name_of_nearest_station?: string

  /**
   * 駅からの距離
   * @example 760
   */
  distance_from_station?: number

  /**
   * 用途区分
   * @example 準工
   */
  use_district: string

  /**
   * 建ぺい率
   * @example 60
   */
  building_coverage?: number

  /**
   * 容積率
   * @example 400
   */
  floor_area_ratio?: number

  /**
   * 直近5年分の調査価格
   * @example [{"year":2021,"value":3840000,"yoy":1.1},{"year":2020,"value":3840000,"yoy":-0.9}]
   */
  prices?: any[]

  /**
   * 緯度
   * @format double
   * @example 35.64386806000005
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.7543469000001
   */
  lng?: number

  /**
   * 対象物件と事例物件の距離
   * @example 356
   */
  distance?: number

  /**
   * 接道状況
   * @example ippou
   */
  corner?: string

  /**
   * 区域区分
   * @example urbanization_area
   */
  area_classification?: string
}

export type Stations = Station[]

export type Lines = Line[]

export type PostedLandPriceStories = PostedLandPriceStory[]

export interface Report {
  /** 査定書ID */
  id: number

  /** ユーザーID */
  user_id?: number

  /** 企業ID */
  company_id: number
  category: ReportCategory
  created_at: string
  updated_at: string
  created_year: number
  created_month: number

  /**
   * 原価法及び取引事例比較法を元に、手動で入力された査定価格が入ります。
   * @example 8888
   */
  assessment_price: number

  /** 査定価格の種別です。(原価法、取引事例比較法、任意の査定価格) */
  assessment_price_category: string

  /**
   * 査定コメント
   * @example ここに査定結果に対するコメントが入ります
   */
  assessment_comment: string

  /**
   * 売出提案価格(下限)
   * @example 7444
   */
  offer_price_lower: number

  /**
   * 売出提案価格(上限)
   * @example 7777
   */
  offer_price: number

  /** 手入力で売出提案価格の入力があった場合、そちらが優先されるようにするためのフラグです。 */
  offer_price_edited: boolean

  /**
   * 売出提案価格コメント
   * @example ここに売出提案価格に対するコメントが入ります
   */
  offer_comment: string

  /**
   * レンジ表記フラグ
   * @example true
   */
  enabled_range: boolean

  /** 査定書の初期化完了フラグ */
  initialized: boolean

  /** 査定書の印刷可能フラグ */
  printable: boolean
  land?: Land
  building?: Building
  condo?: Condo
  cost_approach_assessment?: Assessment
  market_approach_assessment?: Assessment
  sale_price_approach_assessment?: Assessment

  /** 競合分析データ */
  competitor_analysis?: CompetitorAnalysis

  /** 相場分析データ */
  market_analysis?: MarketAnalysis
  print_setting: PrintSetting

  /**
   * その他の補正率（score.etc）に対するコメントが入ります
   * @example 2022年8月にフルリノベーション済みです。
   */
  etc_point_comment: string

  /**
   * 「建物その他」の補正率（score.building_etc）に対するコメントが入ります
   * @example 対象不動産は特別人気のあるマンションです。
   */
  building_etc_point_comment: string
  company: Company

  /** 緯度 */
  lat?: number

  /** 経度 */
  lng?: number

  /** 帳票URLを発行した日時 */
  url_published_at?: string

  /** 共有URLにアクセスされた履歴を取得します（最大5件） */
  report_access_logs?: ReportAccessLog[]

  /** カスタム属性一覧を取得します。 */
  report_custom_fields: ReportCustomField[]

  /** カスタム属性の値を配列で返します。 */
  report_custom_field_values: ReportCustomFieldValue[]
  documents?: Document[]
}

/**
 * 査定書に紐づく写真を保存するテーブルです。
 */
export interface ReportPhoto {
  /** 査定書に紐づく写真のID */
  id: number

  /** 査定書ID */
  report_id: number

  /** 帳票に添付する画像の種類 */
  category: PhotoCategory

  /**
   * 添付ファイル（S3のキー）
   * @example reports/{report_id}/{photo_type}/example.png
   */
  document_key: string

  /**
   * 添付ファイル（URL）
   * @example https://some.where/images/example.png
   */
  document_url: string

  /**
   * 物件写真の名前
   * @example 間取り図・平面図
   */
  name: string

  /**
   * 写真に対するコメント
   * @example ご所有不動産の測量図になります。確定測量が行われたのが15年前なるため、不確定要素を考慮すべきです。売却時には確定測量費用が発生することも想定する必要があります。
   */
  comment: string
}

export interface Land {
  /**
   * 所在地
   * @example 東京都中央区月島1-22-1
   */
  address?: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng?: number

  /** 沿線名 */
  route1_line_name?: string

  /** 沿線ID */
  route1_line_code?: number

  /** 駅名 */
  route1_station_name?: string

  /** 駅ID */
  route1_station_code?: number

  /** 駅徒歩 */
  route1_distance_to_station?: number

  /** バス（停徒歩） */
  route1_distance_to_busstop?: number

  /** バス（乗車時間） */
  route1_distance_to_station_by_bus?: number
  ownership?: Ownership

  /** 借地権割合(所有権の場合は1扱い) */
  leasehold_percentage?: number

  /**
   * 土地面積
   * @format float
   * @example 80.12
   */
  area?: number

  /**
   * 土地面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 24.27
   */
  tsubo?: number
  shape?: LandShape

  /**
   * セットバック面積
   * @format float
   * @example 0
   */
  setback_area?: number
  corner?: LandCorner
  area_classification?: AreaClassification
  use_district?: UseDistrictCode

  /** 建蔽率 */
  building_coverage_ratio?: number

  /** 容積率(指定) */
  specified_floor_area_ratio?: number

  /** 容積率(基準) */
  standard_floor_area_ratio?: number
  fire_prevention_law?: FirePreventionLaw

  /** その他法規制 */
  etc_law?: string

  /** 備考(土地) */
  remarks?: string
  connecting_road1_direction?: Direction
  connecting_road1_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road1_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road1_front_width?: number
  connecting_road2_direction?: Direction
  connecting_road2_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road2_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road2_front_width?: number
  connecting_road3_direction?: Direction
  connecting_road3_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road3_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road3_front_width?: number
  connecting_road4_direction?: Direction
  connecting_road4_type?: RoadType

  /**
   * 幅員(m)
   * @format float
   */
  connecting_road4_road_width?: number

  /**
   * 間口(m)
   * @format float
   */
  connecting_road4_front_width?: number

  /**
   * 旗竿地補正率
   * @format float
   */
  hzt_correction_rate?: number

  /**
   * 奥行
   * @format float
   */
  alley_depth?: number

  /**
   * 有効宅地の価値率
   * @format float
   */
  valid_land_value_rate?: number

  /**
   * 路地状部分の価値率
   * @format float
   */
  invalid_land_value_rate?: number

  /**
   * 有効宅地割合(%)
   * @format float
   */
  valid_land_percentage?: number

  /**
   * 路地状部分割合(%)
   * @format float
   */
  invalid_land_percentage?: number
}

export interface Building {
  construction_type?: BuildingConstructionType

  /**
   * 階数（地上）
   * @example 32
   */
  ground_floor_number?: number

  /**
   * 階数(地下)
   * @example 1
   */
  basement_floor_number?: number

  /**
   * @format float
   * @example 120.12
   */
  area?: number

  /**
   * 建物面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 36.37
   */
  tsubo?: number

  /**
   * 間取り
   * @example 3LDK
   */
  layout?: string

  /**
   * 築年月日(年)
   * @example 2010
   */
  built_year?: number

  /**
   * 築年月日(月)
   * @example 4
   */
  built_month?: number
  current_state?: CurrentState

  /**
   * 施工会社
   * @example 大成建設株式会社
   */
  construct_company_name?: string

  /** 備考(建物) */
  remarks?: string

  /** 建築単価(万円/坪) */
  tsubo_price?: number

  /** 残価率(%) */
  residual_value_percentage?: number

  /** 耐用年数(年) */
  durable_years?: number

  /** 築年数 */
  age?: number

  /** 建物価格 */
  price?: number

  /** 付加箇所1 名称 */
  added_part1_name?: string

  /** 付加箇所1 コメント */
  added_part1_comment?: string

  /** 付加箇所1 価格 */
  added_part1_price?: number

  /** 付加箇所2 名称 */
  added_part2_name?: string

  /** 付加箇所2 コメント */
  added_part2_comment?: string

  /** 付加箇所2 価格 */
  added_part2_price?: number

  /** 付加箇所3 名称 */
  added_part3_name?: string

  /** 付加箇所3 コメント */
  added_part3_comment?: string

  /** 付加箇所3 価格 */
  added_part3_price?: number
}

export interface Condo {
  /** 建物名 */
  building_name?: string

  /** 建物ID(コラビット事例DB) */
  building_id?: number

  /**
   * 所在地
   * @example 東京都中央区月島1-22-1
   */
  address?: string

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng?: number

  /** 沿線名 */
  route1_line_name?: string

  /** 沿線ID */
  route1_line_code?: number

  /** 駅名 */
  route1_station_name?: string

  /** 駅ID */
  route1_station_code?: number

  /** 駅徒歩 */
  route1_distance_to_station?: number

  /** バス（停徒歩） */
  route1_distance_to_busstop?: number

  /** バス（乗車時間） */
  route1_distance_to_station_by_bus?: number
  construction_type?: CondoConstructionType

  /**
   * 総戸数
   * @example 520
   */
  residence_count?: number

  /**
   * 総階数（地上）
   * @example 32
   */
  ground_floor_number?: number

  /**
   * 総階数(地下)
   * @example 1
   */
  basement_floor_number?: number

  /**
   * 築年月日(年)
   * @example 2010
   */
  built_year?: number

  /**
   * 築年月日(月)
   * @example 4
   */
  built_month?: number

  /**
   * 築年
   * @example 12
   */
  age?: number
  ownership?: Ownership

  /**
   * 分譲会社
   * @example 三井不動産レジデンシャル株式会社、丸紅株式会社、大成建設株式会社
   */
  sale_company_name?: string

  /**
   * 施工会社
   * @example 大成建設株式会社
   */
  construct_company_name?: string

  /**
   * 設計会社
   * @example ㈱熊谷組
   */
  architect_company_name?: string

  /**
   * 管理会社
   * @example 三井不動産レジデンシャルサービス株式会社
   */
  management_company_name?: string
  management_form?: CondoManagementForm
  manager_state?: CondoManagementState

  /**
   * 備考(建物)
   * @example 建物に関するメモです。
   */
  building_remarks?: string

  /**
   * 部屋番号
   * @example 1211
   */
  room_name?: string

  /**
   * 所在階
   * @example 12
   */
  room_floor_number?: number

  /**
   * 専有面積(平米)
   * @format float
   * @example 72.8
   */
  room_area?: number

  /**
   * 専有面積(坪)
   * 小数点第三位では四捨五入しています。
   * @format float
   * @example 22.02
   */
  room_tsubo?: number

  /**
   * バルコニー面積
   * @format float
   * @example 10.8
   */
  room_balcony_area?: number

  /**
   * 間取り
   * @example 3LDK
   */
  room_layout?: string
  room_direction?: Direction
  room_current_state?: CurrentState

  /**
   * 管理費(月額)
   * @example 7800
   */
  management_fee?: number

  /**
   * 修繕費(月額)
   * @example 7800
   */
  repairment_fee?: number

  /**
   * その他費用(月額)
   * @example 0
   */
  etc_fee?: number

  /**
   * 分譲価格(万円)
   * @example 4000
   */
  sale_price?: number

  /**
   * 備考(部屋)
   * @example 部屋に関するメモです。
   */
  room_remarks?: number

  /**
   * ルーフバルコニー
   * @example false
   */
  roof_balcony?: boolean

  /**
   * 専用庭
   * @example false
   */
  private_garden?: boolean

  /**
   * 角部屋
   * @example true
   */
  corner?: boolean
}

/**
 * 帳票に紐づく事例データです。
 */
export interface Story {
  /** 事例ID */
  id?: number

  /** 査定書ID */
  report_id?: number
  category?: ReportCategory

  /** 取引の種別 */
  transaction_type?: StoryTransactionType

  /** 事例の発生源 */
  source_type?: StorySourceType

  /** 事例の用途 */
  use?: StoryUse

  /**
   * 成約年月日(年)
   * @example 2010
   */
  traded_year?: number

  /**
   * 成約年月日(月)
   * @example 4
   */
  traded_month?: number

  /**
   * 金額(万円)
   * @example 5500
   */
  price?: number

  /**
   * 分譲価格比(%)
   * sale_price_percentage = price / sale_price
   * (分譲価格が未入力の場合はnull値が返却されます)
   * @example 100
   */
  sale_price_percentage?: number

  /**
   * 建物価格(万円)
   * @example 1500
   */
  building_price?: number

  /**
   * 土地価格(万円)
   * @example 4000
   */
  land_price?: number

  /**
   * 事例の類似度
   * @example 100
   */
  similarity_score?: number

  /** 評価ポイントデータ */
  score?: Score

  /** 添付ファイル（S3のキー） */
  document_key?: string

  /** 添付ファイル（URL） */
  document_url?: string
  land?: Land
  building?: Building
  condo?: Condo

  /** 評価ポイント */
  point?: number

  /** 補正前の坪単価 */
  correction_target_price: number

  /** 補正率 */
  correction_rate?: number

  /** 補正後の坪単価 */
  corrected_price?: number

  /**
   * その他の補正率（score.etc）に対するコメントが入ります
   * @example 2022年8月にフルリノベーション済みです。
   */
  etc_point_comment: string

  /**
   * 「建物その他」の補正率（score.building_etc）に対するコメントが入ります
   * @example 対象不動産に比べて比較的高値で取引される傾向にあるため補正されます。
   */
  building_etc_point_comment: string
}

/**
 * 帳票に紐づく事例データです。
 */
export interface CondoStory {
  /** 事例ID */
  id: number

  /** 査定書ID */
  report_id: number
  category: ReportCategory

  /** 取引の種別 */
  transaction_type: StoryTransactionType

  /** 事例の発生源 */
  source_type: StorySourceType

  /** 事例の用途 */
  use: StoryUse

  /**
   * 成約年月日(年)
   * @example 2010
   */
  traded_year: number

  /**
   * 成約年月日(月)
   * @example 4
   */
  traded_month: number

  /**
   * 金額(万円)
   * @example 5500
   */
  price: number

  /**
   * 分譲価格比(%)
   * sale_price_percentage = price / sale_price
   * (分譲価格が未入力の場合はnull値が返却されます)
   * @example 100
   */
  sale_price_percentage?: number

  /**
   * 事例の類似度
   * @example 100
   */
  similarity_score: number

  /** 評価ポイントデータ */
  score: Score

  /** 添付ファイル（S3のキー） */
  document_key: string

  /** 添付ファイル（URL） */
  document_url: string
  condo: Condo

  /** 評価ポイント */
  point: number

  /** 補正前の坪単価 */
  correction_target_price: number

  /** 補正率 */
  correction_rate: number

  /** 補正後の坪単価 */
  corrected_price: number

  /**
   * その他の補正率（score.etc）に対するコメントが入ります
   * @example 2022年8月にフルリノベーション済みです。
   */
  etc_point_comment: string

  /**
   * 「建物その他」の補正率（score.building_etc）に対するコメントが入ります
   * @example 対象不動産に比べて比較的高値で取引される傾向にあるため補正されます。
   */
  building_etc_point_comment: string
}

export type StoryIdList = number[]

export interface DocumentUrl {
  /** S3 Key */
  key?: string

  /** 保存済みか */
  persisted?: boolean

  /** 添付ファイルのURL(GET) */
  get?: string

  /** 添付ファイルのURL(PUT) */
  put?: string
}

export interface Assessment {
  /** 査定ID */
  id?: number

  /** 査定方法の種別 */
  category?: AssessmentCategory | null

  /**
   * 平均価格
   * (分譲価格比の査定ではnull値となります)
   */
  mean_price?: number

  /**
   * 加重平均価格
   * (分譲価格比の査定ではnull値となります)
   */
  weighted_mean_price?: number

  /**
   * 補正前土地価格（整形地の価格）
   * (戸建の場合のみ返却されます。また取引事例比較法の場合は0が返却されます。)
   */
  land_price?: number

  /**
   * 補正前建物価格
   * (戸建の場合のみ返却されます。)
   */
  building_price?: number

  /**
   * 市場性調整率の補正前の価格
   * - 戸建 > 原価法: 土地価格(補正後の土地事例価格の平均)+建物価格
   * - 戸建 > 取引事例比較法の場合: 補正後の建物事例価格の平均
   * - 土地 > 取引事例比較法の場合: 補正後の土地事例価格の平均
   * - MS  > 取引事例比較法の場合: 補正後のMS事例価格の平均
   * - MS  > 分譲価格比査定の場合: 対象事例の分譲価格 * 事例分譲価格比の平均 / 100.0
   */
  price?: number

  /**
   * 補正率(%)
   * @example 100
   */
  adjustment_percentage?: number

  /**
   * 補正済価格(万円)
   * @example 4000
   */
  adjusted_price?: number

  /** 選択事例 */
  stories?: (Story | CondoStory | (Story & CondoStory))[]

  /** 市場性調整率コメント */
  comment?: string

  /** 査定方法を使用する場合はtrue */
  active?: boolean

  /** 補正前土地価格（旗竿地補正率を加味した価格） */
  hzt_land_price?: number

  /**
   * 事例分譲価格比の平均(%)
   * (分譲価格比の査定以外は一律100.0となります)
   * @example 100
   */
  sale_price_percentage_mean?: number

  /**
   * 事例分譲価格比(%)
   * デフォルトは事例分譲価格比の平均となります。
   * (分譲価格比の査定以外は一律100.0となります)
   * @example 100
   */
  sale_price_percentage?: number
}

/**
 * 競合分析データ
 */
export interface CompetitorAnalysis {
  /** 選択事例 */
  stories?: (Story | CondoStory | (Story & CondoStory))[]
}

/**
 * 相場分析データ
 */
export interface MarketAnalysis {
  /** 選択事例 */
  posted_land_price_stories?: PostedLandPriceStory[]
}

/**
 * 評価ポイントデータ
 */
export interface Score {
  /**
   * @format float
   * @example 1.1
   */
  time?: number

  /**
   * @format float
   * @example 1.1
   */
  address?: number

  /**
   * @format float
   * @example 1.1
   */
  access?: number

  /**
   * @format float
   * @example 1.1
   */
  use?: number

  /**
   * @format float
   * @example 1.1
   */
  corner?: number

  /**
   * @format float
   * @example 1.1
   */
  road_direction?: number

  /**
   * @format float
   * @example 1.1
   */
  road_width?: number

  /**
   * @format float
   * @example 1.1
   */
  road_type?: number

  /**
   * @format float
   * @example 1.1
   */
  ownership?: number

  /**
   * @format float
   * @example 1.1
   */
  area?: number

  /**
   * @format float
   * @example 1.1
   */
  shape?: number

  /**
   * @format float
   * @example 1.1
   */
  setback?: number

  /**
   * @format float
   * @example 1.1
   */
  etc?: number

  /**
   * @format float
   * @example 1.1
   */
  age?: number

  /**
   * @format float
   * @example 1.1
   */
  building_etc?: number

  /**
   * @format float
   * @example 1.1
   */
  room?: number

  /**
   * @format float
   * @example 1.1
   */
  monthly_fee?: number

  /**
   * @format float
   * @example 1.1
   */
  room_etc?: number

  /**
   * @format float
   * @example 1.1
   */
  land_and_building?: number

  /**
   * @format float
   * @example 1.1
   */
  room_floor_number?: number

  /**
   * @format float
   * @example 1.1
   */
  room_position?: number
}

export interface PrintSettingInput {
  /** お客さま名 */
  customer_name?: string

  /** 査定対象 */
  name?: string

  /**
   * 査定日
   * @format date
   */
  proposed_at?: string

  /** 会社名 */
  company_name?: string

  /** 部署・支店 */
  department_name?: string

  /** 担当者名 */
  staff_name?: string

  /** メールアドレス */
  email?: string

  /** 電話番号 */
  tel?: string

  /** FAX番号 */
  fax?: string

  /** 郵便番号 */
  zip?: string

  /** 住所 */
  address?: string

  /** 住所（建物名） */
  building_name?: string

  /** 帳票のカバーの色 */
  color?: PrintSettingColor

  /** 表示しない帳票セクション */
  excluded_pages_keys?: ExcludedPagesKey[]

  /** 表示しない帳票セクション */
  excluded_page_keys?: ExcludedPageKey[]

  /** カスタム帳票の名称 */
  custom_name?: CustomName
}

export interface PrintSetting {
  /** お客さま名 */
  customer_name: string

  /** 査定対象 */
  name: string

  /**
   * 査定日
   * @format date
   */
  proposed_at: string

  /** 会社名 */
  company_name: string

  /** 部署・支店 */
  department_name: string

  /** 担当者名 */
  staff_name?: string

  /** メールアドレス */
  email: string

  /** 電話番号 */
  tel: string

  /** FAX番号 */
  fax: string

  /** 郵便番号 */
  zip: string

  /** 住所 */
  address: string

  /** 住所（建物名） */
  building_name: string

  /** 帳票のカバーの色 */
  color: PrintSettingColor
  greeting_text: string

  /** デフォルトで表示する帳票 */
  default_page_setting: DefaultPageSetting

  /** 表示しない帳票セクション */
  excluded_pages_keys: ExcludedPagesKey[]

  /** 表示しない帳票ページ */
  excluded_page_keys: ExcludedPageKey[]

  /** 表示順の設定 */
  custom_order_keys: ExcludedPagesKey[]

  /** カスタム帳票の名称 */
  custom_name: CustomName

  /** 携帯電話番号 */
  cell_phone_number: string

  /**
   * ページ番号の表示
   * true: 表示有
   * false: 表示なし
   * defaultは表示有りです。
   * @example true
   */
  visible_page_number: boolean
}

export interface PrintSettingPage {
  key: string
  label: string
}

/**
 * デフォルトで表示する帳票
 */
export type DefaultPageSetting = Record<string, PrintSettingPage[]>

export interface Station {
  /**
   * 駅名
   * @example 品川駅
   */
  station_name?: string

  /**
   * 駅コード（グループコード）
   * @example 100202
   */
  station_g_cd?: number

  /**
   * 駅コード
   * @example 100202
   */
  station_cd?: number

  /**
   * 緯度
   * @format double
   * @example 35.663829636289904
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.78193518226857
   */
  lng?: number

  /**
   * 沿線名
   * @example 山手線
   */
  line_name?: string

  /**
   * 路線コード
   * @example 11302
   */
  line_cd?: number

  /**
   * 徒歩分数
   * @example 5
   */
  walk_minutes?: number
}

export interface Line {
  id?: number

  /**
   * 路線名
   * @example 山手線
   */
  line_name?: string

  /**
   * 路線コード
   * @example 11302
   */
  line_cd?: number
}

export interface DetailedDBBuilding {
  /** @example 12345 */
  building_id?: number

  /** @example ミッドタワーグランド */
  name?: string

  /** @example 東京都荒川区南千住８丁目 */
  address?: string

  /** @example １７－７ */
  other_address?: string

  /**
   * 緯度
   * @format double
   * @example 35.64386806000005
   */
  lat?: number

  /**
   * 経度
   * @format double
   * @example 139.7543469000001
   */
  lng?: number

  /** @example 32 */
  floor_number?: number

  /** @example 1 */
  basement_floor_number?: number

  /** @example 1 */
  built_at_year?: number

  /** @example 1 */
  built_at_month?: number

  /** @example 12345 */
  age?: number

  /** @example 363 */
  residence_count?: number
  route1_line_code?: number
  route1_line_name?: string
  route1_station_code?: number
  route1_station_name?: string

  /** @example 3 */
  route1_distance_to_station?: number
  ownership?: Ownership

  /** @example 鹿島建設,西台産業 */
  sale_company_name?: string

  /** @example 三井住友建設㈱ */
  construct_company_name?: string

  /** @example ㈱熊谷組 */
  architect_company_name?: string

  /** @example 日本ハウジング */
  management_company_name?: string
}

export interface DBBuilding {
  /** @example 13118007008 */
  area_cd?: string

  /** @example 東京都荒川区南千住８丁目 */
  address?: string

  /** @example 12345 */
  building_id?: number

  /** @example ミッドタワーグランド */
  name?: string

  /** @example 2020 */
  built_at_year?: number

  /** @example 32 */
  floor_number?: number

  /** @example 1132004 */
  station_cd?: number

  /** @example 11320 */
  line_cd?: number

  /** @example 南千住 */
  station_name?: string
}

export interface DetailedDBRoom {
  name?: string
  floor_number?: number
  m2?: number
  balcony_m2?: number
  floor_plan?: string
  monthly_management_fee?: number
  monthly_repair_fee?: number
  price?: number
  has_roof_balcony?: boolean
  has_garden?: boolean
  kado?: boolean
}

export interface DBRoom {
  name?: string
  floor_plan?: string
  m2?: number
  floor_number?: number

  /**
   * 表示用の文字列です。
   * @example 101(75.33 ㎡)
   */
  display_name?: string
}

export interface AdminCompany {
  id: number
  name: string
  department_name?: string
  plan_name: string
  status: string
  activated_at: string
}

export interface AdminCompanyDetail {
  id: number
  name: string
  address: string
  tel?: string
  zip?: string
  fax?: string
  department_name?: string
  license_number?: string
  memo?: string

  /**
   * 決済情報を管理するモデルです。
   * planに1x1でひも付き、companiesと1*nの関係でひも付きます。
   * ただしcompanyモデルから参照される実装を想定しているのでcompaniesの属性は持たない設計にしています。
   */
  payment: Payment
  users: User[]
  invoices: {
    id: number
    target_year: number
    target_month: number
    plan_name?: string
    monthly_reports_count?: number
    reports_count?: number
    price?: number
    memo?: string
  }[]
}

export interface AdminCompanyEdit {
  id: number
  name: string
  address: string
  tel?: string
  zip?: string
  fax?: string
  department_name?: string
  license_number?: string
  memo?: string
}

export interface DBUseDistrict {
  /**
   * 市区町村コード(5桁)
   * @example 13101
   */
  city_code: string
  code: UseDistrictCode

  /**
   * 建ぺい率(%)
   * @example 80
   */
  building_coverage: number

  /**
   * 容積率(%)
   * @example 500
   */
  floor_area_ratio: number

  /**
   * 指定した緯度経度が含まれている地域かどうか
   * @example false
   */
  is_target: boolean
}

export interface DBUrbanArea {
  area_classification: AreaClassification

  /** 対象事例が該当の都市地域にふくまれているかどうか */
  is_target: boolean
}

export interface HouseSellStoriesStatistic {
  city_area_mean?: number
  city_price_mean?: number
  city_year_data?: { label?: string; count?: number; price_mean?: number }[]
  city_area_data?: { label?: string; count?: number }[]
  city_price_data?: { label?: string; count?: number }[]
}

export interface LandSellStoriesStatistic {
  city_area_mean?: number
  city_price_mean?: number
  city_tsubo_price_mean?: number
  city_year_data?: {
    label?: string
    count?: number
    tsubo_price_mean?: number
  }[]
  city_area_data?: { label?: string; count?: number }[]
  city_price_data?: { label?: string; count?: number }[]
}

export interface CondoSellStoriesStatistic {
  city_area_mean?: number
  city_price_mean?: number
  city_tsubo_price_mean?: number
  city_year_data?: {
    label?: string
    count?: number
    tsubo_price_mean?: number
  }[]
  city_area_data?: { label?: string; count?: number }[]
  city_price_data?: { label?: string; count?: number }[]

  /** 売却期間ごとの件数 */
  city_sales_period_data: { label: string; count: number }[]

  /**
   * 売却期間の中央値(日)
   * @example 76
   */
  city_sales_period_median?: number

  /** 価格改定数ごとの件数 */
  city_price_change_count_data: { label: string; count: number }[]

  /**
   * 平均価格改定数
   * @example 2.5
   */
  city_price_change_count_mean?: number

  /** 価格改定率ごとの件数 */
  city_price_change_rate_data: { label: string; count: number }[]

  /**
   * 平均価格改定率
   * @example 0.051
   */
  city_price_change_rate_mean?: number
  building_area_mean?: number
  building_price_mean?: number
  building_tsubo_price_mean?: number
  building_year_data?: {
    label?: string
    count?: number
    tsubo_price_mean?: number
  }[]
  building_area_data?: { label?: string; count?: number }[]
  building_price_data?: { label?: string; count?: number }[]
  building_stories?: {
    ad_start_at?: string
    ad_end_at?: string
    selling?: boolean
    floor_number?: number
    area: number
    layout?: string
    open_price: number
    open_tsubo_price: number
    close_price: number
    close_tsubo_price: number
  }[]
}

export interface Spot {
  category?: string
  name?: string
  walk_distance?: number
  walk_minutes?: number
  address?: string
  lat?: number
  lng?: number
  spot_type?: string
}

export interface TransportNode {
  /**
   * レコードid
   * @example 120607537
   */
  id: number

  /**
   * 駅/バス停の交通機関会社名
   * @example 東京都交通局
   */
  company_name?: string

  /**
   * 駅/バス停の路線名
   * @example 都営大江戸線（環状部）
   */
  line_name?: string

  /**
   * 駅/バス停の名称
   * @example 綾瀬
   */
  name?: string

  /**
   * 駅/バス停の名称の読み仮名
   * @example あやせ
   */
  ruby?: string

  /**
   * ノードタイプの一覧
   * @example ["station"]
   */
  types?: TransportNodeType[]

  /**
   * 住所の表示名
   * @example 東京都
   */
  address_name?: string

  /**
   * 駅/バス停の中心緯度
   * @example 35.762031
   */
  lat: number

  /**
   * 駅/バス停の中心経度
   * @example 139.823465
   */
  lng: number

  /**
   * 出発地点緯度経度からの距離(m)
   * @example 346
   */
  distance?: number

  /**
   * 所要時間(分)
   * @example 4
   */
  time?: number

  /** ルート検索（徒歩）結果の地理データ(Geojson) */
  shape?: {
    type?: string
    features?: {
      type?: string
      bbox?: number[]
      geometry?: { type?: string; coordinates?: number[][] }
      properties?: object
    }[]
    bbox?: number[]
  }
}

export interface TransportLink {
  id: number
  types: ('train' | 'bus')[]
  line_name: string
  stations: { id: string; station_name: string; is_nearest_station: boolean }[]
}

export interface UseDistrict {
  id?: number
  code?: UseDistrictCode
  city_code?: number
  building_coverage?: number
  floor_area_ratio?: number
  is_target?: boolean
  polygon?: number[][][]
}

/**
 * 都市地域データ
 */
export interface UrbanArea {
  /** 都市地域データID */
  id: number
  area_classification: AreaClassification

  /** 対象事例が該当の都市地域にふくまれているかどうか */
  is_target: boolean

  /** ポリゴンデータ */
  polygon: number[][][]
}

export interface CrowdCity {
  /**
   * 地区世帯数
   * @example 1234
   */
  number_households: number

  /**
   * 建物総棟数
   * @example 1234
   */
  building_number: number

  /**
   * 住所
   * @example 荒川区 荒川４丁目地区
   */
  address: string

  /**
   * 直線距離
   * @example 512
   */
  distance: number

  /** 地理データ(Polygon) */
  shape: number[][][]
}

export interface SedimentDisasterHazardArea {
  line: {
    remarks: string
    sediment_disasters_prone_area_cd: number
    line?: number[][]
  }[]
  point: {
    remarks: string
    sediment_disasters_prone_area_cd: number
    point?: number[]
  }[]
  surface: {
    remarks: string
    sediment_disasters_prone_area_cd: number
    surface: number[][][]
  }[]
}

export interface ExpectedTsunamiInundatedArea {
  /** 都道府県名 */
  prefecture_name: string

  /** 範囲 */
  bounds: number[][][]

  /** 都道府県コード */
  prefecture_code: string

  /** 津波浸水深の区分 */
  classification_of_water_depth: string

  /** 対象地域がどうか */
  is_target: boolean
  level: WaterDepthLevel
}

export interface AreasExpectedToBeFloodedByStormSurge {
  /** 範囲 */
  bounds: number[][][]

  /** 高潮浸水深の区分 */
  classification_of_water_depth: string

  /** 対象地域がどうか */
  is_target: boolean
  level: WaterDepthLevel
}

export interface InundationHazardousArea {
  /** 都道府県コード(国管理の場合は81-89の番号、都道府県管理の場合は、1-47の番号が入る) */
  prefecture_code: number

  /** 河川名 */
  river_name: string

  /** 津波浸水深の区分 */
  classification_of_water_depth: number

  /** 対象地域がどうか */
  is_target: boolean
  level: WaterDepthLevel

  /** 範囲 */
  bounds: number[][][]
}

export interface JapanSeismicHazardFault {
  /**
   * 断層コード
   * https://www.j-shis.bosai.go.jp/map/JSHIS2/data/DOC/DataFileRule/A-RULES.pdf
   * 上記URLの93Pに記載有
   */
  code: string

  /** 断層の名称 */
  name: string

  /** 断層への距離距離(m) */
  distance?: number

  /** ポリゴンデータ */
  shape: number[][][]
}

export interface JapanSeismicHazardMesh {
  /** 250mメッシュコード10桁 */
  meshcode: number

  /** 微地形分類コード */
  code: number

  /** 微地形分類名称 */
  name: string

  /** 工学的基盤 (Vs=400m/s)から地表に至る最大速度の増幅率 */
  amplification_factor: number

  /** 30年間で震度5弱以上となる確率 */
  probability_of_exceedance_ijma_gte_5_lower_within_30_years: number

  /** 30年間で震度5強以上となる確率 */
  probability_of_exceedance_ijma_gte_5_upper_within_30_years: number

  /** 30年間で震度6弱以上となる確率 */
  probability_of_exceedance_ijma_gte_6_lower_within_30_years: number

  /** 30年間で震度6強以上となる確率 */
  probability_of_exceedance_ijma_gte_6_upper_within_30_years: number

  /**
   * 液状化の可能性
   * https://docs.google.com/spreadsheets/d/1wpmD0jHFm4yN49g8degmkQyqdPX_wuk_o676O216p4g/edit#gid=1516355427
   */
  probability_of_liquefaction?: JapanSeismicHazardMeshProbabilityOfLiquefactionType

  /** ポリゴンデータ */
  shape: number[][][]

  /** 対象地かどうか */
  is_target: boolean
}

export interface CondoSellStory {
  area_m2?: number
  price?: number
  age?: number
  lat?: number
  lng?: number
  building_id?: number
}

export interface HouseSellStory {
  building_area_m2?: number
  price?: number
  age?: number
}

export interface LandSellStory {
  land_area_m2?: number
  price?: number
  connect_road_width1?: number
}

export interface CondoStatisticArea {
  count: number
  error_rate: number
  area_detail_name: string
  correction_rate: number
  lat: number
  lng: number
  is_target: boolean
  polygon: number[][][]
}

export interface HouseStatisticArea {
  count: number
  error_rate: number
  area_detail_name: string
  correction_rate: number
  lat: number
  lng: number
  is_target: boolean
  polygon: number[][][]
}

export interface LandStatisticArea {
  count: number
  error_rate: number
  area_detail_name: string
  correction_rate: number
  lat: number
  lng: number
  is_target: boolean
  polygon: number[][][]
}

export interface HeatMapEstimatePrice {
  isBase: boolean
  cityName?: string
  areaName?: string
  detailName?: string
  lat: number
  lng: number
  estimatePrice?: { standardPrice?: number }
  shape: number[][][]
}

export interface RentHeatMapEstimatePrice {
  isBase: boolean
  cityName?: string
  areaName?: string
  detailName?: string
  lat: number
  lng: number
  estimatePrice?: { rentPrice25?: number }
  shape: number[][][]
}

export interface ReportAuthentication {
  id: string
  token: string
  password: string
  has_changed?: boolean
}

export interface ElevationAndSlopeAngleFifthMesh {
  /** メッシュの中心間距離 */
  distance?: number

  /** 位置 */
  direction?: string

  /** 最小傾斜度 */
  slope_angle_min?: number

  /** 最小傾斜方向 */
  min_slope_angle_direction?: string

  /** 最大傾斜方向 */
  max_slope_angle_direction?: string

  /** 平均傾斜度 */
  slope_angle_avg?: number

  /** 最大傾斜度 */
  slope_angle_max?: number

  /** 最小標高(m) */
  elevation_min?: number

  /** 最大標高(m) */
  elevation_max?: number

  /** 平均標高(m) */
  elevation_avg?: number

  /** ポリゴンデータ */
  shape: number[][][]
}

export interface LowPriceArea {
  /** 最大浸水深(m) */
  max_flood_depth?: number

  /** 面積(ha) */
  area?: number

  /** 住所 */
  address?: string

  /** 対象地からポリゴンの先端までの距離 */
  distance: number

  /** ポリゴン最北の緯度 */
  lat: number

  /** ポリゴン最北の経度 */
  lng: number

  /** ポリゴンデータ */
  shape: number[][][]
}

export interface HeatMapIncomePerPerson {
  isBase: boolean
  cityName?: string
  areaName?: string
  detailName?: string
  lat: number
  lng: number
  chome2020?: { incomePerPerson?: number }
  shape: number[][][]
}

export interface ExitSimulation {
  id: number
  report_id: number

  /**
   * 手残り金額です。
   * 売買金額から諸費用を引いた金額です。
   * @example 38000000
   */
  exit_price: number

  /**
   * 有効フラグ
   * @example true
   */
  active: boolean

  /**
   * 編集済みフラグ
   * @example false
   */
  items_edited: boolean
  items: ExitSimulationItem[]
}

export interface ExitSimulationItem {
  /** 項目名 */
  name: string

  /** コメント */
  comment: string

  /** 金額 */
  amount: number

  /** 計算上で負の値として取り扱うかどうか(負の値の場合にtrue) */
  negative: boolean

  /**
   * base: 売買金額
   * commission: 仲介手数料
   * etc: それ以外
   */
  type: string
}

export interface CustomOffer {
  id: number
  report_id: number

  /** 項目名 */
  name: string

  /** 金額(万円)(下限) */
  price_lower: number

  /** 金額(万円)(上限) */
  price: number
  active: boolean

  /**
   * レンジ表記フラグ
   * @example true
   */
  enabled_range: boolean
}

export interface ReportAccessLog {
  /** アクセス履歴のID */
  id: number

  /** 帳票ID */
  report_id: number

  /** IPアドレス */
  ip_address: string

  /** 参照元 */
  referrer: string

  /** アクセスが発生した時間 */
  created_at: string
}

/**
 * 取り込み用事例データファイル
 */
export interface StoriesImportFile {
  id: number

  /**
   * 帳票ID
   * @example 12345
   */
  report_id: number

  /**
   * 取り込み用事例データファイルのステータス
   * unknown: 不明
   * wait: 取り込み処理が未実行
   * active: 取り込み処理を実行中
   * completed: 取り込み処理が完了
   * failed: 取り込み処理が失敗
   */
  status: ImportFileStatus

  /**
   * 取り込みに失敗した場合のメッセージ
   * @example TODO
   */
  error_message?: string
  category: ReportCategory

  /** 取引の種別 */
  transaction_type: StoryTransactionType

  /** 事例の用途 */
  use: StoryUse

  /**
   * 添付ファイル（S3のキー）
   * @example reports/{report_id}/stories_import_files/example.csv
   */
  document_key: string
  created_at?: string
  updated_at?: string
}

export interface Error {
  /** @format int32 */
  code: number
  message: string
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://localhost:8010',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      requestParams.headers.common = { Accept: '*/*' }
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title AI査定API
 * @version 0.0.1
 * @baseUrl http://localhost:8010
 *
 * AI査定プロ・プラスで使用するAPIです。
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyList
     * @summary アカウント一覧
     * @request GET:/admin/companies
     * @secure
     */
    adminCompanyList: (query?: { page?: number }, params: RequestParams = {}) =>
      this.request<{ companies: AdminCompany[]; total_pages: number }, any>({
        path: `/admin/companies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyCreate
     * @summary アカウント作成
     * @request POST:/admin/companies
     * @secure
     */
    adminCompanyCreate: (
      data: {
        plan_id: string
        password: string
        activated_at: string
        company: {
          name: string
          zip?: string
          address: string
          tel?: string
          fax?: string
          department_name?: string
          license_number?: string
        }
        user: { email: string; family_name?: string; given_name?: string }
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyDetail
     * @summary アカウント詳細
     * @request GET:/admin/companies/{company_id}
     * @secure
     */
    adminCompanyDetail: (companyId: string, params: RequestParams = {}) =>
      this.request<AdminCompanyDetail, any>({
        path: `/admin/companies/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyUpdate
     * @summary アカウント更新
     * @request PUT:/admin/companies/{company_id}
     * @secure
     */
    adminCompanyUpdate: (
      companyId: string,
      data: AdminCompanyEdit,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/companies/${companyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyDelete
     * @summary アカウント削除
     * @request DELETE:/admin/companies/{company_id}
     * @secure
     */
    adminCompanyDelete: (companyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/companies/${companyId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminCompanyEdit
     * @summary アカウント
     * @request GET:/admin/companies/{company_id}/edit
     * @secure
     */
    adminCompanyEdit: (companyId: string, params: RequestParams = {}) =>
      this.request<AdminCompanyEdit, any>({
        path: `/admin/companies/${companyId}/edit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserCreate
     * @summary ユーザー作成
     * @request POST:/admin/users
     * @secure
     */
    adminUserCreate: (
      data: {
        company_id: number
        email: string
        password: string
        family_name?: string
        given_name?: string
        tel1?: string
        tel2?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/admin/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserDetail
     * @summary アカウント
     * @request GET:/admin/users/{user_id}
     * @secure
     */
    adminUserDetail: (userId: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/admin/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserUpdate
     * @summary ユーザー更新
     * @request PUT:/admin/users/{user_id}
     * @secure
     */
    adminUserUpdate: (userId: string, data: User, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/admin/users/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminUserEdit
     * @summary アカウント
     * @request GET:/admin/users/{user_id}/edit
     * @secure
     */
    adminUserEdit: (userId: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/admin/users/${userId}/edit`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name PlanList
     * @summary プラン一覧
     * @request GET:/admin/plans
     * @secure
     */
    planList: (params: RequestParams = {}) =>
      this.request<Plan[], any>({
        path: `/admin/plans`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name AdminInvoicesList
     * @summary 請求情報の一覧
     * @request GET:/admin/invoices
     * @secure
     */
    adminInvoicesList: (
      query?: { target_year?: string; target_month?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ invoices: Invoice[]; total_pages: number }, any>({
        path: `/admin/invoices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags admin
     * @name UpdatePayment
     * @summary 利用ステータス更新
     * @request PUT:/admin/payments/{payment_id}
     * @secure
     */
    updatePayment: (
      paymentId: number,
      data: { status?: PaymentStatus; plan_id?: number },
      params: RequestParams = {}
    ) =>
      this.request<Payment, any>({
        path: `/admin/payments/${paymentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags users
     * @name UsersMeDetail
     * @summary ユーザー詳細
     * @request GET:/users/me
     * @secure
     */
    usersMeDetail: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UsersMeUpdate
     * @summary ユーザー編集
     * @request PUT:/users/me
     * @secure
     */
    usersMeUpdate: (data: User, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/users/me`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内のユーザーの一覧を返却します。
     *
     * @tags users
     * @name UsersList
     * @summary ユーザー一覧
     * @request GET:/offices/{office_id}/users
     * @secure
     */
    usersList: (officeId: string, params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/offices/${officeId}/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内にユーザーを作成します。
     *
     * @tags users
     * @name UsersCreate
     * @summary ユーザー作成
     * @request POST:/offices/{office_id}/users
     * @secure
     */
    usersCreate: (officeId: string, data: User, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/offices/${officeId}/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定したユーザーの情報を取得します。 認証したユーザーが所属する企業内のユーザーのみ対象です。
     *
     * @tags users
     * @name UsersDetail
     * @summary ユーザー詳細
     * @request GET:/offices/{office_id}/user/{user_id}
     * @secure
     */
    usersDetail: (
      userId: number,
      officeId: string,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/offices/${officeId}/user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定したユーザーの情報を更新します。 認証したユーザーが所属する企業内のユーザーのみ対象です。
     *
     * @tags users
     * @name UsersUpdate
     * @summary ユーザー更新
     * @request PUT:/offices/{office_id}/user/{user_id}
     * @secure
     */
    usersUpdate: (
      userId: number,
      officeId: string,
      data: User,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/offices/${officeId}/user/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定したユーザーの情報を論理削除します。 認証したユーザーが所属する企業内のユーザーのみ対象です。
     *
     * @tags users
     * @name UsersDelete
     * @summary ユーザー削除
     * @request DELETE:/offices/{office_id}/user/{user_id}
     * @secure
     */
    usersDelete: (
      userId: number,
      officeId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/offices/${officeId}/user/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  offices = {
    /**
     * @description 認証したユーザーが所属する企業内の店舗の一覧を返却します。
     *
     * @tags offices
     * @name OfficesList
     * @summary 店舗一覧
     * @request GET:/offices
     * @secure
     */
    officesList: (params: RequestParams = {}) =>
      this.request<Office[], any>({
        path: `/offices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 認証したユーザーが所属する企業内に店舗を追加します。
     *
     * @tags offices
     * @name OfficesCreate
     * @summary 店舗作成
     * @request POST:/offices
     * @secure
     */
    officesCreate: (data: Office, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/offices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定した店舗の情報を取得します。 認証したユーザーが所属する企業内の店舗のみ対象です。
     *
     * @tags offices
     * @name OfficesDetail
     * @summary 店舗詳細
     * @request GET:/offices/{office_id}
     * @secure
     */
    officesDetail: (officeId: number, params: RequestParams = {}) =>
      this.request<Office, any>({
        path: `/offices/${officeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した店舗の情報を更新します。 認証したユーザーが所属する企業内の店舗のみ対象です。
     *
     * @tags offices
     * @name OfficesUpdate
     * @summary 店舗更新
     * @request PUT:/offices/{office_id}
     * @secure
     */
    officesUpdate: (
      officeId: number,
      data: Office,
      params: RequestParams = {}
    ) =>
      this.request<Office, any>({
        path: `/offices/${officeId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した店舗の情報を論理削除します。 認証したユーザーが所属する企業内の店舗のみ対象です。
     *
     * @tags offices
     * @name OfficesDelete
     * @summary 店舗削除
     * @request DELETE:/offices/{office_id}
     * @secure
     */
    officesDelete: (officeId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/offices/${officeId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  documents = {
    /**
     * @description 自分が作成した独自資料の一覧を返却します。
     *
     * @tags documents
     * @name DocumentsList
     * @summary 独自資料一覧
     * @request GET:/documents
     * @secure
     */
    documentsList: (query?: { page?: number }, params: RequestParams = {}) =>
      this.request<{ documents: Document[]; total_page_count: number }, any>({
        path: `/documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 独自資料を作成します。
     *
     * @tags documents
     * @name DocumentsCreate
     * @summary 独自資料作成
     * @request POST:/documents
     * @secure
     */
    documentsCreate: (data: Document, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定した独自資料の情報を取得します。 自分の作成した独自資料のみ対象です。
     *
     * @tags documents
     * @name DocumentsDetail
     * @summary 独自資料詳細
     * @request GET:/documents/{document_id}
     * @secure
     */
    documentsDetail: (documentId: number, params: RequestParams = {}) =>
      this.request<Document, any>({
        path: `/documents/${documentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した独自資料の情報を更新します。 自分の作成した独自資料のみ対象です。
     *
     * @tags documents
     * @name DocumentsUpdate
     * @summary 独自資料更新
     * @request PUT:/documents/{document_id}
     * @secure
     */
    documentsUpdate: (
      documentId: number,
      data: Document,
      params: RequestParams = {}
    ) =>
      this.request<Document, any>({
        path: `/documents/${documentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した独自資料の情報を論理削除します。 自分の作成した独自資料のみ対象です。
     *
     * @tags documents
     * @name DocumentsDelete
     * @summary 独自資料削除
     * @request DELETE:/documents/{document_id}
     * @secure
     */
    documentsDelete: (documentId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/documents/${documentId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsDetailUrl
     * @summary 事例の添付ファイル(PDF、画像)
     * @request GET:/documents/url
     * @secure
     */
    documentsDetailUrl: (query: { key: string }, params: RequestParams = {}) =>
      this.request<DocumentUrl, any>({
        path: `/documents/url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  reports = {
    /**
     * No description
     *
     * @tags reports
     * @name ReportsList
     * @summary 査定書一覧
     * @request GET:/reports
     * @secure
     */
    reportsList: (
      query?: { page?: number; category?: string; keyword?: string },
      params: RequestParams = {}
    ) =>
      this.request<{ reports: Report[]; total_page_count: number }, any>({
        path: `/reports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsCreate
     * @summary 査定書作成
     * @request POST:/reports
     * @secure
     */
    reportsCreate: (data: Report, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsDetail
     * @summary 査定書詳細
     * @request GET:/reports/{report_id}
     * @secure
     */
    reportsDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<Report, any>({
        path: `/reports/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsUpdate
     * @summary 査定書更新
     * @request PUT:/reports/{report_id}
     * @secure
     */
    reportsUpdate: (
      reportId: number,
      data: Report,
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 査定書を論理削除します。 認証したユーザーが作成した査定書のみ対象です。
     *
     * @tags reports
     * @name ReportsDelete
     * @summary 査定書削除
     * @request DELETE:/reports/{report_id}
     * @secure
     */
    reportsDelete: (reportId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${reportId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportEtcPointCommentUpdate
     * @summary 査定書のその他ポイントのコメント更新
     * @request PATCH:/reports/{report_id}/etc_point_comment
     * @secure
     */
    reportEtcPointCommentUpdate: (
      reportId: number,
      data: { etc_point_comment?: string },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/etc_point_comment`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportBuildingEtcPointCommentUpdate
     * @summary 査定書のその他ポイントのコメント更新
     * @request PATCH:/reports/{report_id}/building_etc_point_comment
     * @secure
     */
    reportBuildingEtcPointCommentUpdate: (
      reportId: number,
      data: { building_etc_point_comment?: string },
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/building_etc_point_comment`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportCustomFieldValuesUpdate
     * @summary 帳票のカスタム属性を更新します。
     * @request PATCH:/reports/{report_id}/report_custom_field_values
     * @secure
     */
    reportCustomFieldValuesUpdate: (
      reportId: number,
      data: ReportCustomFieldValue[],
      params: RequestParams = {}
    ) =>
      this.request<Report, any>({
        path: `/reports/${reportId}/report_custom_field_values`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ExitSimulationCreate
     * @summary レポートに紐づく手残りシミュレーションの作成
     * @request POST:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationCreate: (
      reportId: string,
      data: ExitSimulation,
      params: RequestParams = {}
    ) =>
      this.request<ExitSimulation[], Error>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name CustomOfferCreate
     * @summary レポートに紐づく第三の価格の作成
     * @request POST:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferCreate: (
      reportId: string,
      data: CustomOffer,
      params: RequestParams = {}
    ) =>
      this.request<CustomOffer[], Error>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports
     * @name ReportsUpdateDocuments
     * @summary 査定書に紐づく独自資料を更新
     * @request PATCH:/reports/{report_id}/documents
     * @secure
     */
    reportsUpdateDocuments: (
      reportId: number,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<Report, Error>({
        path: `/reports/${reportId}/documents`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  stories = {
    /**
     * No description
     *
     * @tags stories
     * @name StoriesList
     * @summary 事例一覧
     * @request GET:/reports/{report_id}/stories
     * @secure
     */
    storiesList: (
      reportId: number,
      query?: {
        use?:
          | 'cost_approach_assessment'
          | 'market_approach_assessment'
          | 'competitor_analysis'
        source_type?: 'db' | 'posted'
        page?: number
        per_page?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<(Story | CondoStory | (Story & CondoStory))[], any>({
        path: `/reports/${reportId}/stories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesCreate
     * @summary 事例作成
     * @request POST:/reports/{report_id}/stories
     * @secure
     */
    storiesCreate: (
      reportId: number,
      data: Story | CondoStory | (Story & CondoStory),
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/stories`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesDetail
     * @summary 事例詳細
     * @request GET:/reports/{report_id}/stories/{story_id}
     * @secure
     */
    storiesDetail: (
      reportId: number,
      storyId: number,
      params: RequestParams = {}
    ) =>
      this.request<Story | CondoStory | (Story & CondoStory), any>({
        path: `/reports/${reportId}/stories/${storyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesUpdate
     * @summary 事例更新
     * @request PUT:/reports/{report_id}/stories/{story_id}
     * @secure
     */
    storiesUpdate: (
      reportId: number,
      storyId: number,
      data: Story | CondoStory | (Story & CondoStory),
      params: RequestParams = {}
    ) =>
      this.request<Story | CondoStory | (Story & CondoStory), any>({
        path: `/reports/${reportId}/stories/${storyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesUpdateScores
     * @summary 評価ポイントの更新
     * @request PATCH:/reports/{report_id}/stories/scores
     * @secure
     */
    storiesUpdateScores: (
      reportId: number,
      data: { id?: number; score?: Score; etc_point_comment?: string }[],
      params: RequestParams = {}
    ) =>
      this.request<
        { id?: number; score?: Score; etc_point_comment?: string }[],
        Error
      >({
        path: `/reports/${reportId}/stories/scores`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stories
     * @name StoriesUpdateWeights
     * @summary ウェイトの更新
     * @request PATCH:/reports/{report_id}/stories/weights
     * @secure
     */
    storiesUpdateWeights: (
      reportId: number,
      data: { id?: number; weight?: number }[],
      params: RequestParams = {}
    ) =>
      this.request<{ id?: number; weight?: number }[], Error>({
        path: `/reports/${reportId}/stories/weights`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  reportAccessLogs = {
    /**
     * No description
     *
     * @tags report_access_logs
     * @name ReportAccessLogsCreate
     * @summary 履歴作成
     * @request POST:/reports/{report_id}/report_access_logs
     * @secure
     */
    reportAccessLogsCreate: (reportId: number, params: RequestParams = {}) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/report_access_logs`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  }
  storiesImportFiles = {
    /**
     * @description POSTするとS3のファイルを解析して取り込む処理が非同期で実行されます。
     *
     * @tags storiesImportFiles
     * @name StoriesImportFilesCreate
     * @summary 取り込み用事例データファイル作成
     * @request POST:/reports/{report_id}/stories_import_files
     * @secure
     */
    storiesImportFilesCreate: (
      reportId: number,
      data: StoriesImportFile,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/stories_import_files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  assessments = {
    /**
     * No description
     *
     * @tags assessments
     * @name AssessmentsDetail
     * @summary 査定データの詳細
     * @request GET:/reports/{report_id}/assessments/{category}
     * @secure
     */
    assessmentsDetail: (
      reportId: number,
      category: AssessmentCategory,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name AssessmentsUpdate
     * @summary 査定データの更新
     * @request PATCH:/reports/{report_id}/assessments/{category}
     * @secure
     */
    assessmentsUpdate: (
      reportId: number,
      category: AssessmentCategory,
      data: Assessment,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name AssessmentsUpdateStories
     * @summary 査定データの更新(紐づくstoryを変更)
     * @request PUT:/reports/{report_id}/assessments/{category}/stories
     * @secure
     */
    assessmentsUpdateStories: (
      reportId: number,
      category: AssessmentCategory,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, Error>({
        path: `/reports/${reportId}/assessments/${category}/stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  competitorAnalyses = {
    /**
     * No description
     *
     * @tags competitorAnalyses
     * @name CompetitorAnalysesUpdateStories
     * @summary 競合分析データの更新(紐づくstoryを変更)
     * @request PUT:/reports/{report_id}/competitor_analysis/stories
     * @secure
     */
    competitorAnalysesUpdateStories: (
      reportId: number,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<CompetitorAnalysis, Error>({
        path: `/reports/${reportId}/competitor_analysis/stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  printSettings = {
    /**
     * No description
     *
     * @tags printSettings
     * @name PrintSettingsDetail
     * @summary 印刷設定
     * @request GET:/reports/{report_id}/print_setting
     * @secure
     */
    printSettingsDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<PrintSetting, any>({
        path: `/reports/${reportId}/print_setting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags printSettings
     * @name PrintSettingUpdate
     * @summary 印刷設定更新
     * @request PUT:/reports/{report_id}/print_setting
     * @secure
     */
    printSettingUpdate: (
      reportId: number,
      data: PrintSettingInput,
      params: RequestParams = {}
    ) =>
      this.request<PrintSetting, any>({
        path: `/reports/${reportId}/print_setting`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  reportPhotos = {
    /**
     * @description 帳票に紐づく画像の一覧を返却します。 categoryでフィルターできます。
     *
     * @tags report_photos
     * @name ReportPhotosList
     * @summary 帳票画像の一覧
     * @request GET:/reports/{report_id}/report_photos
     * @secure
     */
    reportPhotosList: (
      reportId: string,
      query?: { category?: string },
      params: RequestParams = {}
    ) =>
      this.request<
        { report_photos: ReportPhoto[]; total_page_count: number },
        any
      >({
        path: `/reports/${reportId}/report_photos`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 帳票に紐づく画像を作成します。
     *
     * @tags report_photos
     * @name ReportPhotosCreate
     * @summary 帳票画像の作成
     * @request POST:/reports/{report_id}/report_photos
     * @secure
     */
    reportPhotosCreate: (
      reportId: string,
      data: ReportPhoto,
      params: RequestParams = {}
    ) =>
      this.request<void, Error>({
        path: `/reports/${reportId}/report_photos`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 指定した帳票写真IDの情報を返却します。
     *
     * @tags report_photos
     * @name ReportPhotosDetail
     * @summary 帳票写真の詳細
     * @request GET:/reports/{report_id}/report_photos/{report_photo_id}
     * @secure
     */
    reportPhotosDetail: (
      reportPhotoId: number,
      reportId: string,
      params: RequestParams = {}
    ) =>
      this.request<ReportPhoto, any>({
        path: `/reports/${reportId}/report_photos/${reportPhotoId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した帳票写真IDの情報を更新します。
     *
     * @tags report_photos
     * @name ReportPhotosUpdate
     * @summary 帳票写真の更新
     * @request PUT:/reports/{report_id}/report_photos/{report_photo_id}
     * @secure
     */
    reportPhotosUpdate: (
      reportPhotoId: number,
      reportId: string,
      data: ReportPhoto,
      params: RequestParams = {}
    ) =>
      this.request<ReportPhoto, any>({
        path: `/reports/${reportId}/report_photos/${reportPhotoId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定した帳票写真の情報を削除します。
     *
     * @tags report_photos
     * @name ReportPhotosDelete
     * @summary 帳票写真の削除
     * @request DELETE:/reports/{report_id}/report_photos/{report_photo_id}
     * @secure
     */
    reportPhotosDelete: (
      reportPhotoId: number,
      reportId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/reports/${reportId}/report_photos/${reportPhotoId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  stations = {
    /**
     * No description
     *
     * @tags stations
     * @name StationsList
     * @summary 駅名一覧
     * @request GET:/stations
     * @secure
     */
    stationsList: (
      query: { line_cd: number; page?: number; lat: number; lng: number },
      params: RequestParams = {}
    ) =>
      this.request<Stations, any>({
        path: `/stations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  lines = {
    /**
     * No description
     *
     * @tags lines
     * @name LinesList
     * @summary 指定した緯度経度から50km範囲内の路線一覧
     * @request GET:/lines
     * @secure
     */
    linesList: (
      query: { lat: number; lng: number },
      params: RequestParams = {}
    ) =>
      this.request<Lines, any>({
        path: `/lines`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  buildings = {
    /**
     * No description
     *
     * @tags buildings
     * @name BuildingsList
     * @summary 指定した文字列を名称に含むマンションの一覧
     * @request GET:/db/buildings
     * @secure
     */
    buildingsList: (
      query: { name: string; address: string },
      params: RequestParams = {}
    ) =>
      this.request<DBBuilding[], any>({
        path: `/db/buildings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags buildings
     * @name BuildingsDetail
     * @summary マンション一覧から選択された建物情報の詳細を返す
     * @request GET:/db/buildings/{building_id}
     * @secure
     */
    buildingsDetail: (buildingId: string, params: RequestParams = {}) =>
      this.request<DetailedDBBuilding, any>({
        path: `/db/buildings/${buildingId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  rooms = {
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsList
     * @summary 指定したマンションIDの部屋一覧
     * @request GET:/db/rooms
     * @secure
     */
    roomsList: (query: { building_id: number }, params: RequestParams = {}) =>
      this.request<DBRoom[], any>({
        path: `/db/rooms`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags rooms
     * @name RoomsDetail
     * @summary 部屋一覧から選択された部屋情報の詳細を返す
     * @request GET:/db/buildings/{building_id}/room
     * @secure
     */
    roomsDetail: (
      buildingId: number,
      query: { name: string; area_m2: number },
      params: RequestParams = {}
    ) =>
      this.request<DetailedDBRoom, any>({
        path: `/db/buildings/${buildingId}/room`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  dbUseDistricts = {
    /**
     * No description
     *
     * @tags db/use_districts
     * @name UseDistrictDetail
     * @summary 指定した緯度経度の近隣の用途地域情報を返却します。
     * @request GET:/db/use_districts
     * @secure
     */
    useDistrictDetail: (
      query: { lat: number; lng: number; distance?: number },
      params: RequestParams = {}
    ) =>
      this.request<DBUseDistrict[], any>({
        path: `/db/use_districts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  dbUrbanAreas = {
    /**
     * No description
     *
     * @tags db/urban_areas
     * @name UrbanAreaDetail
     * @summary 指定した緯度経度の近隣の都市地域データを返却します。
     * @request GET:/db/urban_areas
     * @secure
     */
    urbanAreaDetail: (
      query: { lat: number; lng: number; distance?: number },
      params: RequestParams = {}
    ) =>
      this.request<DBUrbanArea[], any>({
        path: `/db/urban_areas`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  postedLandPriceStories = {
    /**
     * No description
     *
     * @tags postedLandPriceStories
     * @name PostedLandPriceStoriesList
     * @summary 公示地価一覧（半径2キロ圏内）
     * @request GET:/reports/{report_id}/posted_land_price_stories
     * @secure
     */
    postedLandPriceStoriesList: (
      reportId: string,
      params: RequestParams = {}
    ) =>
      this.request<PostedLandPriceStories, any>({
        path: `/reports/${reportId}/posted_land_price_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postedLandPriceStories
     * @name PostedLandPriceUpdateStories
     * @summary 相場情報の事例一覧を更新する
     * @request PUT:/reports/{report_id}/market_analysis/posted_land_price_stories
     * @secure
     */
    postedLandPriceUpdateStories: (
      reportId: number,
      data: StoryIdList,
      params: RequestParams = {}
    ) =>
      this.request<PostedLandPriceStory, Error>({
        path: `/reports/${reportId}/market_analysis/posted_land_price_stories`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  sellStoriesStatistics = {
    /**
     * No description
     *
     * @tags sellStoriesStatistics
     * @name CondoSellStoriesStatisticDetail
     * @summary MS売買事例の統計量を返却する
     * @request GET:/reports/{report_id}/condo_sell_stories_statistic
     * @secure
     */
    condoSellStoriesStatisticDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<CondoSellStoriesStatistic, any>({
        path: `/reports/${reportId}/condo_sell_stories_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sellStoriesStatistics
     * @name HouseSellStoriesStatisticDetail
     * @summary 戸建売買事例の統計量を返却する
     * @request GET:/reports/{report_id}/house_sell_stories_statistic
     * @secure
     */
    houseSellStoriesStatisticDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<HouseSellStoriesStatistic, any>({
        path: `/reports/${reportId}/house_sell_stories_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags sellStoriesStatistics
     * @name LandSellStoriesStatisticDetail
     * @summary 土地売買事例の統計量を返却する
     * @request GET:/reports/{report_id}/land_sell_stories_statistic
     * @secure
     */
    landSellStoriesStatisticDetail: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<LandSellStoriesStatistic, any>({
        path: `/reports/${reportId}/land_sell_stories_statistic`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  spots = {
    /**
     * No description
     *
     * @tags spots
     * @name SpotsList
     * @summary レポートに紐づく周辺施設一覧
     * @request GET:/reports/{report_id}/spots
     * @secure
     */
    spotsList: (reportId: number, params: RequestParams = {}) =>
      this.request<Spot[], any>({
        path: `/reports/${reportId}/spots`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  useDistricts = {
    /**
     * No description
     *
     * @tags useDistricts
     * @name UseDistrictList
     * @summary レポートに紐づく用途地域一覧
     * @request GET:/reports/{report_id}/use_districts
     * @secure
     */
    useDistrictList: (reportId: number, params: RequestParams = {}) =>
      this.request<UseDistrict[], any>({
        path: `/reports/${reportId}/use_districts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  urbanAreas = {
    /**
     * No description
     *
     * @tags urbanAreas
     * @name UrbanAreasList
     * @summary レポートに紐づく都市地域データ一覧
     * @request GET:/reports/{report_id}/urban_areas
     * @secure
     */
    urbanAreasList: (
      reportId: number,
      query?: { area_classification?: AreaClassification },
      params: RequestParams = {}
    ) =>
      this.request<UrbanArea[], any>({
        path: `/reports/${reportId}/urban_areas`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  crowdCities = {
    /**
     * No description
     *
     * @tags crowdCities
     * @name CrowdCitiesList
     * @summary レポートに紐づく密集市街地
     * @request GET:/reports/{report_id}/crowd_cities
     * @secure
     */
    crowdCitiesList: (reportId: number, params: RequestParams = {}) =>
      this.request<CrowdCity[], any>({
        path: `/reports/${reportId}/crowd_cities`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  elevationAndSlopeAngleFifthMeshes = {
    /**
     * No description
     *
     * @tags elevationAndSlopeAngleFifthMeshes
     * @name ElevationAndSlopeAngleFifthMeshesList
     * @summary 標高・傾斜度5次メッシュ情報一覧API
     * @request GET:/reports/{report_id}/elevation_and_slope_angle_fifth_meshes
     * @secure
     */
    elevationAndSlopeAngleFifthMeshesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<ElevationAndSlopeAngleFifthMesh[], any>({
        path: `/reports/${reportId}/elevation_and_slope_angle_fifth_meshes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  lowPriceAreas = {
    /**
     * No description
     *
     * @tags lowPriceAreas
     * @name LowPriceAreasList
     * @summary 低位地帯データ情報一覧API
     * @request GET:/reports/{report_id}/low_price_areas
     * @secure
     */
    lowPriceAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<LowPriceArea[], any>({
        path: `/reports/${reportId}/low_price_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  sedimentDisasterHazardAreas = {
    /**
     * No description
     *
     * @tags sedimentDisasterHazardAreas
     * @name SedimentDisasterHazardAreasList
     * @summary レポートに紐づく危険箇所データ
     * @request GET:/reports/{report_id}/sediment_disaster_hazard_areas
     * @secure
     */
    sedimentDisasterHazardAreasList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<SedimentDisasterHazardArea, any>({
        path: `/reports/${reportId}/sediment_disaster_hazard_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  expectedTsunamiInundatedAreas = {
    /**
     * No description
     *
     * @tags expectedTsunamiInundatedAreas
     * @name ExpectedTsunamiInundatedAreasList
     * @summary 津波浸水想定データ一覧API
     * @request GET:/reports/{report_id}/expected_tsunami_inundated_areas
     * @secure
     */
    expectedTsunamiInundatedAreasList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<ExpectedTsunamiInundatedArea[], any>({
        path: `/reports/${reportId}/expected_tsunami_inundated_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  areasExpectedToBeFloodedByStormSurges = {
    /**
     * No description
     *
     * @tags areasExpectedToBeFloodedByStormSurges
     * @name AreasExpectedToBeFloodedByStormSurgesList
     * @summary 高潮浸水想定データ一覧API
     * @request GET:/reports/{report_id}/areas_expected_to_be_flooded_by_storm_surges
     * @secure
     */
    areasExpectedToBeFloodedByStormSurgesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<AreasExpectedToBeFloodedByStormSurge[], any>({
        path: `/reports/${reportId}/areas_expected_to_be_flooded_by_storm_surges`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  inundationHazardousAreas = {
    /**
     * No description
     *
     * @tags inundationHazardousAreas
     * @name InundationHazardousAreasList
     * @summary 河川浸水想定データ一覧API
     * @request GET:/reports/{report_id}/inundation_hazardous_areas
     * @secure
     */
    inundationHazardousAreasList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<InundationHazardousArea[], any>({
        path: `/reports/${reportId}/inundation_hazardous_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  japanSeismicHazard = {
    /**
     * No description
     *
     * @tags japanSeismicHazard
     * @name JapanSeismicHazardFaultsList
     * @summary 国立研究開発法人防災科学技術研究所の活断層一覧API
     * @request GET:/reports/{report_id}/japan_seismic_hazard_faults
     * @secure
     */
    japanSeismicHazardFaultsList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<JapanSeismicHazardFault[], any>({
        path: `/reports/${reportId}/japan_seismic_hazard_faults`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags japanSeismicHazard
     * @name JapanSeismicHazardMeshesList
     * @summary 国立研究開発法人防災科学技術研究所のメッシュ一覧API
     * @request GET:/reports/{report_id}/japan_seismic_hazard_meshes
     * @secure
     */
    japanSeismicHazardMeshesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<JapanSeismicHazardMesh[], any>({
        path: `/reports/${reportId}/japan_seismic_hazard_meshes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  condoSellStories = {
    /**
     * No description
     *
     * @tags condoSellStories
     * @name CondoSellStoriesList
     * @summary MS事例一覧API
     * @request GET:/reports/{report_id}/condo_sell_stories
     * @secure
     */
    condoSellStoriesList: (reportId: number, params: RequestParams = {}) =>
      this.request<CondoSellStory[], any>({
        path: `/reports/${reportId}/condo_sell_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  houseSellStories = {
    /**
     * No description
     *
     * @tags houseSellStories
     * @name HouseSellStoriesList
     * @summary 戸建事例一覧API
     * @request GET:/reports/{report_id}/house_sell_stories
     * @secure
     */
    houseSellStoriesList: (reportId: number, params: RequestParams = {}) =>
      this.request<HouseSellStory[], any>({
        path: `/reports/${reportId}/house_sell_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  landSellStories = {
    /**
     * No description
     *
     * @tags landSellStories
     * @name LandSellStoriesList
     * @summary 土地事例一覧API
     * @request GET:/reports/{report_id}/land_sell_stories
     * @secure
     */
    landSellStoriesList: (reportId: number, params: RequestParams = {}) =>
      this.request<LandSellStory[], any>({
        path: `/reports/${reportId}/land_sell_stories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  statisticAreas = {
    /**
     * No description
     *
     * @tags statisticAreas
     * @name CondoStatisticAreasList
     * @summary MS地域別の事例統計量の一覧API
     * @request GET:/reports/{report_id}/condo_statistic_areas
     * @secure
     */
    condoStatisticAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<CondoStatisticArea[], any>({
        path: `/reports/${reportId}/condo_statistic_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags statisticAreas
     * @name HouseStatisticAreasList
     * @summary 戸建の地域別の事例統計量の一覧API
     * @request GET:/reports/{report_id}/house_statistic_areas
     * @secure
     */
    houseStatisticAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<HouseStatisticArea[], any>({
        path: `/reports/${reportId}/house_statistic_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags statisticAreas
     * @name LandStatisticAreasList
     * @summary 土地の地域別の事例統計量の一覧API
     * @request GET:/reports/{report_id}/land_statistic_areas
     * @secure
     */
    landStatisticAreasList: (reportId: number, params: RequestParams = {}) =>
      this.request<LandStatisticArea[], any>({
        path: `/reports/${reportId}/land_statistic_areas`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  heatMapEstimatePrices = {
    /**
     * No description
     *
     * @tags heatMapEstimatePrices
     * @name HeatMapEstimatePricesList
     * @summary MS推定値ポリゴン情報一覧API
     * @request GET:/reports/{report_id}/heat_map_estimate_prices
     * @secure
     */
    heatMapEstimatePricesList: (reportId: number, params: RequestParams = {}) =>
      this.request<HeatMapEstimatePrice[], any>({
        path: `/reports/${reportId}/heat_map_estimate_prices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  rentHeatMapEstimatePrices = {
    /**
     * No description
     *
     * @tags rentHeatMapEstimatePrices
     * @name RentHeatMapEstimatePricesList
     * @summary MS賃料推定値ポリゴン情報一覧API
     * @request GET:/reports/{report_id}/rent_heat_map_estimate_prices
     * @secure
     */
    rentHeatMapEstimatePricesList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<RentHeatMapEstimatePrice[], any>({
        path: `/reports/${reportId}/rent_heat_map_estimate_prices`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  transportNodes = {
    /**
     * No description
     *
     * @tags transportNodes
     * @name TransportNodesList
     * @summary レポートに紐づく交通機関(駅・バス停)一覧
     * @request GET:/reports/{report_id}/transport_nodes
     * @secure
     */
    transportNodesList: (reportId: number, params: RequestParams = {}) =>
      this.request<TransportNode[], any>({
        path: `/reports/${reportId}/transport_nodes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  transportLinks = {
    /**
     * No description
     *
     * @tags transportLinks
     * @name TransportLinksList
     * @summary レポートに紐づく路線一覧
     * @request GET:/reports/{report_id}/transport_links
     * @secure
     */
    transportLinksList: (reportId: number, params: RequestParams = {}) =>
      this.request<TransportLink[], any>({
        path: `/reports/${reportId}/transport_links`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  reportAuthentication = {
    /**
     * No description
     *
     * @tags ReportAuthentication
     * @name Login
     * @summary 共有URL認証API
     * @request POST:/reports/{report_id}/shared_reports/{token}/log_in
     * @secure
     */
    login: (
      reportId: string,
      token: string,
      data: { password: string },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/reports/${reportId}/shared_reports/${token}/log_in`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportAuthentication
     * @name Create
     * @summary 共有URL作成API
     * @request POST:/reports/{report_id}/report_authentications
     * @secure
     */
    create: (reportId: string, params: RequestParams = {}) =>
      this.request<ReportAuthentication, any>({
        path: `/reports/${reportId}/report_authentications`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportAuthentication
     * @name Update
     * @summary 共有URLパスワード変更API
     * @request PATCH:/reports/{report_id}/report_authentications/{report_authentication_id}
     * @secure
     */
    update: (
      reportId: string,
      reportAuthenticationId: string,
      data: { password: string },
      params: RequestParams = {}
    ) =>
      this.request<ReportAuthentication, any>({
        path: `/reports/${reportId}/report_authentications/${reportAuthenticationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  heatMapIncomePerPerson = {
    /**
     * No description
     *
     * @tags heatMapIncomePerPerson
     * @name HeatMapIncomePerPersonList
     * @summary 年収マップ一覧API
     * @request GET:/reports/{report_id}/heat_map_income_per_people
     * @secure
     */
    heatMapIncomePerPersonList: (
      reportId: number,
      params: RequestParams = {}
    ) =>
      this.request<HeatMapIncomePerPerson[], any>({
        path: `/reports/${reportId}/heat_map_income_per_people`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  exitSimulation = {
    /**
     * No description
     *
     * @tags exitSimulation
     * @name ExitSimulationDetail
     * @summary レポートに紐づく手残りシミュレーション
     * @request GET:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<ExitSimulation | null, any>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exitSimulation
     * @name ExitSimulationItemUpdate
     * @summary レポートに紐づく手残りシミュレーションを更新
     * @request PUT:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationItemUpdate: (
      reportId: number,
      data: ExitSimulation,
      params: RequestParams = {}
    ) =>
      this.request<ExitSimulation[], any>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags exitSimulation
     * @name ExitSimulationItemDelete
     * @summary レポートに紐づく手残りシミュレーションを削除
     * @request DELETE:/reports/{report_id}/exit_simulation
     * @secure
     */
    exitSimulationItemDelete: (reportId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${reportId}/exit_simulation`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  customOffer = {
    /**
     * No description
     *
     * @tags customOffer
     * @name CustomOfferDetail
     * @summary レポートに紐づく第三の価格
     * @request GET:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferDetail: (reportId: number, params: RequestParams = {}) =>
      this.request<CustomOffer | null, any>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags customOffer
     * @name CustomOfferUpdate
     * @summary レポートに紐づく第三の価格を更新
     * @request PUT:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferUpdate: (
      reportId: number,
      data: CustomOffer,
      params: RequestParams = {}
    ) =>
      this.request<CustomOffer[], any>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags customOffer
     * @name CustomOfferDelete
     * @summary レポートに紐づく第三の価格を削除
     * @request DELETE:/reports/{report_id}/custom_offer
     * @secure
     */
    customOfferDelete: (reportId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/reports/${reportId}/custom_offer`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  wait = {
    /**
     * No description
     *
     * @tags wait
     * @name Wait
     * @summary Wait処理用のエンドポイント
     * @request GET:/wait
     * @secure
     */
    wait: (params: RequestParams = {}) =>
      this.request<{ message?: string }, any>({
        path: `/wait`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  areaInformationReports = {
    /**
     * No description
     *
     * @tags areaInformationReports
     * @name AreaInformationReportCreate
     * @summary エリアレポートの作成
     * @request POST:/area_information_reports
     * @secure
     */
    areaInformationReportCreate: (
      data: {
        id: number
        company_id: number
        created_at: string
        updated_at: string
        created_year: number
        created_month: number
        initialized: boolean
        printable: boolean
        print_setting: PrintSetting
        company: Company
      },
      params: RequestParams = {}
    ) =>
      this.request<any, Error>({
        path: `/area_information_reports`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
