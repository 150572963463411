import { mutationTree, getterTree, actionTree } from 'typed-vuex'

export const state = () => ({})

export const getters = getterTree(state, {
  constructionTypeItems: (state) => {
    return [
      { text: 'RC', value: 'rc' },
      { text: 'SRC', value: 'src' },
      { text: '軽量鉄骨', value: 'lgs' },
      { text: '重量鉄骨', value: 'wgs' },
      { text: '重量鉄骨', value: 'cb' },
      { text: '木造', value: 'wood' },
      { text: 'その他', value: 'other' },
      { text: '不明', value: 'unknown' },
    ]
  },
  ownershipItems: (state) => {
    return [
      { text: '所有権', value: 'freehold' },
      { text: '所有権(一部借地権)', value: 'mostly_freehold' },
      { text: '借地権', value: 'leasehold' },
      { text: '定期借地権', value: 'limited_leasehold', appendDivider: true },
      { text: '旧法賃借権', value: 'old_leasehold' },
      { text: '地上権(旧法)', value: 'old_superficies' },
      { text: '普通賃借権', value: 'normal_leasehold' },
      { text: '地上権(新法)', value: 'normal_superficies' },
      { text: '定期賃借権', value: 'limited_normal_leasehold' },
      { text: '定期地上権', value: 'limited_superficies' },
      { text: '不明', value: 'unknown' },
    ]
  },
  managementFormItems: (state) => {
    return [
      { text: '全部委託', value: 'all_consignment' },
      { text: '一部委託', value: 'partial_consignment' },
      { text: '自主管理', value: 'self_management' },
      { text: '不明', value: 'unknown' },
    ]
  },
  managerStateItems: (state) => {
    return [
      { text: '日勤', value: 'nikkin' },
      { text: '常駐', value: 'jochu' },
      { text: '巡回', value: 'junkai' },
      { text: '住込', value: 'sumikomi' },
      { text: '不明', value: 'unknown' },
    ]
  },
  roomDirectionItems: (state) => {
    return [
      { text: '南', value: 'south' },
      { text: '東', value: 'east' },
      { text: '西', value: 'west' },
      { text: '北', value: 'north' },
      { text: '南東', value: 'southeast' },
      { text: '南西', value: 'southwest' },
      { text: '北東', value: 'northeast' },
      { text: '北西', value: 'northwest' },
      { text: '不明', value: 'unknown' },
    ]
  },
  roomCurrentStateItems: (state) => {
    return [
      { text: '居住中', value: 'living' },
      { text: '空室', value: 'vacant' },
      { text: '賃貸中', value: 'rent' },
      { text: '建築中', value: 'under_construction' },
      { text: '不明', value: 'unknown' },
    ]
  },
})

export const mutations = mutationTree(state, {})

export const actions = actionTree({ state, getters, mutations }, {})
