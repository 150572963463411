import { getAccessorType } from 'typed-vuex'

// Import all your submodules
// import * as submodule from '~/store/submodule'
import * as report from '~/store/report'
import * as land from '~/store/land'
import * as building from '~/store/building'
import * as condo from '~/store/condo'
import * as story from '~/store/story'
import * as page from '~/store/page'
import * as users from '~/store/users'

// Keep your existing vanilla Vuex code for state, getters, mutations, actions, plugins, etc.
// ...

export const state = () => {
  return {}
}

export const getters = {
  //
}

export const mutations = {
  //
}

export const actions = {
  //
}

// This compiles to nothing and only serves to return the correct type of the accessor
export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
  modules: {
    // The key (submodule) needs to match the Nuxt namespace (e.g. ~/store/submodule.ts)
    // submodule,
    report,
    land,
    building,
    condo,
    story,
    page,
    users,
  },
})
