import { mutationTree, getterTree, actionTree } from 'typed-vuex'

export const state = () => ({
  // data: {} as LandFormInput,
})

export const getters = getterTree(state, {
  // data: state => state.data,

  ownershipItems: (state) => {
    return [
      { text: '所有権', value: 'freehold' },
      { text: '所有権(一部借地権)', value: 'mostly_freehold' },
      { text: '借地権', value: 'leasehold' },
      { text: '定期借地権', value: 'limited_leasehold', appendDivider: true },
      { text: '旧法賃借権', value: 'old_leasehold' },
      { text: '地上権(旧法)', value: 'old_superficies' },
      { text: '普通賃借権', value: 'normal_leasehold' },
      { text: '地上権(新法)', value: 'normal_superficies' },
      { text: '定期賃借権', value: 'limited_normal_leasehold' },
      { text: '定期地上権', value: 'limited_superficies' },
      { text: '不明', value: 'unknown' },
    ]
  },
  // todo: formで共通のmixinを定義し、管理するようにする
  // ownershipItems: state => {
  //   return [
  //     'freehold',
  //     'mostly_freehold',
  //     'leasehold',
  //     'limited_leasehold',
  //     '-',
  //     'old_leasehold',
  //     'old_superficies',
  //     'normal_leasehold',
  //     'normal_superficies',
  //     'limited_normal_leasehold',
  //     'limited_superficies',
  //     'unknown',
  //   ]
  // },

  shapeItems: (state) => {
    return [
      { text: '整形', value: 'shaped_land' },
      { text: '不整形', value: 'unshaped_land' },
      { text: '旗竿地（袋地・路地状敷地・敷延・専通）', value: 'alley_shape_land' },
      // 次のフェーズで対応
      // {text: '自由入力', value: 'manual'},
      { text: '不明', value: 'unknown' },
    ]
  },

  cornerItems: (state) => {
    return [
      { text: '一方', value: 'ippou' },
      { text: '角地', value: 'kado' },
      { text: '二方', value: 'nihou' },
      { text: '三方', value: 'sanhou' },
      { text: '四方', value: 'yonhou' },
      { text: '接道なし', value: 'nashi' },
      { text: '不明', value: 'unknown' },
    ]
  },
  areaClassificationItems: (state) => {
    return [
      { text: '市街化区域', value: 'urbanization_area' },
      { text: '市街化調整区域', value: 'urbanization_control_area' },
      { text: '非線引き都市計画区域', value: 'suburbs_area' },
      { text: '準都市計画区域', value: 'semi_city_planning_area' },
      { text: '都市計画区域外', value: 'outside_area' },
      { text: '不明', value: 'unknown' },
    ]
  },
  useDistrictItems: (state) => {
    return [
      { text: '第一種低層住居専用地域', value: 'itei' },
      { text: '第二種低層住居専用地域', value: 'nitei' },
      { text: '第一種中高層住居専用地域', value: 'ichu' },
      { text: '第二種中高層住居専用地域', value: 'nichu' },
      { text: '第一種住居地域', value: 'ijyu' },
      { text: '第二種住居地域', value: 'nijyu' },
      { text: '田園住居地域', value: 'denjyu' },
      { text: '準住居地域', value: 'jyunjyu' },
      { text: '近隣商業地域', value: 'kinsho' },
      { text: '商業地域', value: 'shogyo' },
      { text: '準工業地域', value: 'jyunko' },
      { text: '工業地域', value: 'kogyo' },
      { text: '工業専用地域', value: 'kosen' },
      { text: '指定無', value: 'unspecified' },
      { text: '不明', value: 'unknown' },
    ]
  },
  firePreventionLawItems: (state) => {
    return [
      { text: '防火地域', value: 'fire_preventive_district' },
      { text: '準防火地域', value: 'semi_fire_preventive_district' },
      { text: '法22条区域', value: 'hou22jo_district' },
      { text: '指定なし', value: 'unspecified' },
      { text: '不明', value: 'unknown' },
    ]
  },
  directionItems: (state) => {
    return [
      { text: '南', value: 'south' },
      { text: '東', value: 'east' },
      { text: '西', value: 'west' },
      { text: '北', value: 'north' },
      { text: '南東', value: 'southeast' },
      { text: '南西', value: 'southwest' },
      { text: '北東', value: 'northeast' },
      { text: '北西', value: 'northwest' },
      { text: '不明', value: 'unknown' },
    ]
  },
  roadTypeItems: (state) => {
    return [
      { text: '公道', value: 'public_road' },
      { text: '私道', value: 'private_road' },
      { text: '不明', value: 'unknown' },
    ]
  },
})

export const mutations = mutationTree(state, {
  // setData(state, data: LandFormInput): void {
  //   state.data = data
  // },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // buildConnectingRoad({ commit }) : void {
    //   const connectingRoad = {
    //     direction: 'unknown',
    //     roadType: 'unknown',
    //     roadWidth: NaN,
    //     frontWidth: NaN,
    //   } as ConnectingRoad
    //   commit('addConnectingRoads', connectingRoad)
    // },
  }
)
