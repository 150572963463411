//
//
//
//

// import Authenticator from '~/components/Authenticator.vue'

export default {
  // components: { Authenticator },

  data() {
    return {}
  },

  head() {
    return {
      bodyAttrs: {
        class: 'A4',
      },
    }
  },

  computed: {},

  mounted() {
    // 印刷ページではfont-size-rootを9pxとする。
    // このように指定すれば同等となる。
    // 参考: https://github.com/vuetifyjs/vuetify/search?q=%24font-size-root
    document.getElementsByTagName('html')[0].style.fontSize = '9px'
  },
}
