import { render, staticRenderFns } from "./AppHeading5.vue?vue&type=template&id=ffa11e66&scoped=true&"
import script from "./AppHeading5.vue?vue&type=script&lang=ts&"
export * from "./AppHeading5.vue?vue&type=script&lang=ts&"
import style0 from "./AppHeading5.vue?vue&type=style&index=0&id=ffa11e66&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffa11e66",
  null
  
)

export default component.exports