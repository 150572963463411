export default async ({ store, $auth }) => {
  // 認証済の場合のみuser情報を取得する

  // Memo(Safariの問題)
  // Safariの場合auth0のユニバーサルログインの認証直後は正常に認証されない。(理由は不明)
  // その場合は$auth.$state.loggedInがfalseになり、後続の処理でAPIアクセスがあった際に401が返却されます。
  // そしてplugins/api.jsのエラーハンドリングの処理によりログアウトされます。

  if ($auth.$state.loggedIn) {
    await store.dispatch('users/fetchUser')
  }
}
